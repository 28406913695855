.nodata-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 200px;
  margin: auto;

  &.size {
    height: 100px;

    img {
      width: 300px;
    }
  }

  img {
    height: 80%;
  }
}
