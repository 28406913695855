.sk-topschool-banner {
  background: #f4f4f4;
  padding: 40px 0;
}
.sk-Topmiddle-sec {
  padding: 40px 0 0;
}
.sk-spaceBottom-school {
  padding-bottom: 20px;
}
.sk-storyHeading-top {
  font-size: 48px;
  font-weight: 600;
}
.sk-all-school p {
  margin-bottom: 0;
}
.desktop_view_city_selct ul {
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 15px;
  max-height: 260px;
  overflow: auto;
}
.desktop_view_city_selct .sk-cityschool-remove {
  border: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}
.mobile_view_city_selct .MuiPaper-elevation {
  box-shadow: none !important;
  border: 1px solid #d9d9d9;
}
.mobile_view_city_selct .MuiCollapse-entered {
  border-top: 1px solid #d9d9d9;
}
.mobile_view_city_selct ul {
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 15px;
  max-height: 260px;
  overflow: auto;
}
.mobile_view_city_selct ul:first-child {
  border-bottom: 0 !important;
}
.mobile_view_city_selct .sk-cityschool-remove {
  border: 0;
  margin-bottom: 0;
}
.desktop_view_city_selct ul:last-child {
  border-bottom: 0;
}

.sk-rightlist-school {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0 25px;
  flex-wrap: wrap;
}
.desktop_view_city_selct:before {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  bottom: 0;
  left: auto;
  width: 1px;
  height: 100%;
  background: #d9d9d9;
}
.desktop_view_city_selct p {
  font-size: 18px;
  padding-bottom: 15px;
  font-weight: 500;
  color: #434242;
  text-transform: capitalize;
}
.desktop_view_city_selct {
  position: relative;
  height: auto;
}
.sk-resetFilter-bar ul {
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d9d9d9 !important;
  padding: 40px 0 35px;
}

.sk-resetFilter-bar {
  padding-right: 30px;
}

.sk-resetFilter-bar ul li {
  font-size: 18px;
  font-weight: 500;
  color: #202020;
}
.sk-topSchoolbox-list ul li {
  position: relative;
  padding-left: 15px;
}

.sk-topSchoolbox-list ul li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  margin: auto;
  width: 6px;
  height: 6px;
  background: #c6c6c6;
  border-radius: 50px;
  bottom: 0;
}
.sk-topSchoolbox-list ul li:before:last-child {
  position: relative;
}
.sk-resetFilter-bar ul li span {
  font-size: 16px;
  font-weight: 400;
  color: #d62c79;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.sk-resetFilter-bar ul li span svg {
  font-size: 22px;
}
.sk-lessshow-more {
  padding-left: 42px;
  color: #d62c79;
  cursor: pointer;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 15px;
  margin-bottom: 20px;
}
.sk-Schooolborder-top {
  border-top: 1px solid #d9d9e9;
}
.sk-all-school p {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 18px;
}

.sk-all-school p span {
  font-size: 16px;
  font-weight: 400;
  color: #888;
}
.sk-topSchoolbox-list {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
  border: 1px solid #d9d9d9;
  margin-bottom: 30px;
  border-radius: 2px;
}

.sk-topLeftimg-box {
  flex: 224px 0 0;
}
.sk-topLeftimg-box {
  text-align: center;
  padding: 10px 0 0 0;
}

.sk-topLeftimg-box span {
  display: inline-block;
  padding: 10px;
  text-transform: uppercase;
  color: #f2b0cf;
  font-size: 9px;
}
.sk-topLeftimg-box img {
  max-width: 120px;
  padding: 1px;
}
.top_col_content {
  flex: 1 1 0;
  padding: 15px;
}
.sk-innerContent-design {
  font-size: 20px;
}
.sk-innerContent-design a {
  color: #202020;
  text-decoration: none;
  margin-bottom: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sk-serachTop-school {
  display: flex;
  align-items: center;
  min-width: 280px;
  flex: 280px 0 0;
  width: 100%;
}

.sk-serachTop-school input {
  height: 48px;
  border-radius: 8px 0 0 8px;
  font-size: 14px;
}
.sk-serachTop-school input:focus {
  border-color: #fe9ec9;
}
.sk-add-title {
  position: absolute;
  left: 0;
  top: 0;
  h6 {
    font-size: 12px;
    margin-bottom: 0;
    font-weight: 400;
    color: #888;
    line-height: 14px;
  }
}
.sk-serachTop-school button {
  height: 48px;
  border-radius: 0px 8px 8px 0;
  padding: 0 15px;
}

.mainDiv_school {
  margin-top: 50px;
}

.career_tit {
  margin-bottom: 10px;
}

.career_tit h2 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 52px;
  color: #323232;
}

.career_tit p {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #828282;
}

.career_box {
  background: #ffffff;
  box-shadow: none;
  border-radius: 2px;
  align-items: center;
  display: grid;
  margin-bottom: 30px;
  border: 1px solid #d9d9d9;
}

.career_logo_img {
  width: 100%;
}

.career_logo_box {
  background: #fff;
  padding: 10px;
  border: 1px solid #d1d1d1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.career_box h2 {
  font-family: Poppins;
  text-transform: capitalize;
  color: #323232;
  font-size: 22px;
  line-height: 1.3125rem;
  margin-top: 0;
  margin-bottom: 5px;
  margin-left: -15px;
  max-width: 100%;
  overflow: hidden;
  // white-space: nowrap;
  text-overflow: ellipsis;
}

.css-ztrqiq-MuiImageListItemBar-root {
  border-radius: 15px;
}

.career_img {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
}

.sk-topSchoolbox-list p {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #828282;
  margin-bottom: 0px;
}

.sk-topSchoolbox-list p span {
  color: #4f4f4f;
}

.sk-topSchoolbox-list ul {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 0;
  list-style: none;
  max-width: 100%;
  margin-bottom: 5px;
  align-items: center;
  // margin-bottom: 30px;
}
.sk-Topview-more a {
  color: #202020;
}
.sk-Topview-more a:hover {
  color: #ec498a;
}
.sk-topSchoolbox-list ul li {
  font-weight: 400;
  color: #434242;
}
.sk-topSchoolbox-list ul li a {
  color: #ec498a;
}

.sk-topSchoolbox-list ul li span {
  color: #888888;
}

#school-left-col {
  height: fit-content;
  position: sticky;
}

.sk-topSchoolbox-list button {
  background: #ec498a;
  border: 1px solid #ec498a;
  box-sizing: border-box;
  border-radius: 2px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  padding: 13px 31px;
  margin-top: 20px;
}

.css-1lizpae-MuiImageListItemBar-titleWrap {
  padding: 65px 16px;
  color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 163px;
  text-align: center;
  display: none;
}

.MuiImageListItemBar-title.css-dasnyc-MuiImageListItemBar-title,
.MuiImageListItemBar-subtitle.css-hk6gwf-MuiImageListItemBar-subtitle {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
}

@media screen and (min-device-width: 50px) and (max-device-width: 767px) {
  .career_box {
    background: #ffffff;
    width: 100%;
    height: 268px;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 16%), 0 0 0 1px rgb(0 0 0 / 8%);
    border-radius: 5px;
    padding: 15px;
    align-items: center;
    display: grid;
    margin-bottom: 30px;
  }

  .career_tit h2 {
    font-size: 24px;
  }

  .career_box h3 {
    padding-top: 15px;
  }

  .top_col_content {
    border-bottom: 1px solid #e3e4eb;
    padding-bottom: 30px;
  }

  .career_box p {
    font-size: 14px;
  }

  .career_box ul li {
    font-size: 14px;
  }

  .career_img img {
    border-radius: 10px;
  }

  .career_tit p {
    display: none;
  }

  .accordion_box_all {
    display: none;
    padding: 0;
  }

  .career_tit {
    margin-bottom: 25px;
  }

  .mainDiv_school {
    padding: 15px 0;
  }

  .career_box .col-md-7.col-12 .career_img {
    order: 1;
  }

  .career_box .col-md-7.col-12 {
    order: 2;
  }

  .filter_side .accordion_box_all {
    display: block;
  }

  .filter_side {
    display: block !important;
  }

  .accordion_box_all ul li .MuiFormControlLabel-label {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    // color: #ec498a;
  }

  .searchSectionSchool {
    width: 180px !important;
  }

  .ads_school {
    margin: 10px 0px !important;
    height: 46px !important;
    width: 100% !important;
    // width: 80% !important;
  }

  .ads_school_box {
    // height: 100px !important;
    // margin-left: 15px !important;
    margin-bottom: 20px !important;
    width: 92% !important;
  }

  .searchSection {
    margin-left: 0px !important;
  }
}

.school_logo_box {
  margin-left: -15px;
  height: 130px;
  width: 130px;
}

.filter_side {
  display: none;
}

.filter_side {
  background: #ffffff;
  border: 1px solid #e3e4eb;
  padding: 67px 30px;
  border-radius: 0px;
  height: 100%;
  width: 90%;
  margin-left: auto;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999999999;
}

.filter_side img {
  position: absolute;
  right: 5%;
  top: 4%;
}

.filter_side p.more {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #323232;
  margin: 0;
}

.filter_side ul {
  margin: 0;
}

.career_box_add {
  background: #ffffff;
  width: 100%;
  margin: 0px 15px;
  align-items: center;
  display: grid;
  margin-bottom: 30px;
}

.google_add_right {
  width: 100%;
}

.google_add_school {
  width: 100%;
  padding-top: 60px;
}

.search_box_row {
  margin-top: 40px;
}

.searchInput {
  width: 340px;
}

.searchIcon {
  cursor: pointer;
  width: 50%;
}

.searchBtn1 {
  border: none;
  width: 55px;
  height: 39px;
  background-color: #ec498a;
  padding: 10px;
}
.closeBtn1 {
  border: none;
  width: 39px;
  height: 39px;
  margin-top: -1px;

  // background-color: #ec498a;
}
.searchclose {
  width: 40px;
  cursor: pointer;
  height: 40px;

  // margin-left: 12px;
}

.searchSection {
  margin-left: 75px;
  margin-top: 15px;
}

.ads_school_cover {
  text-align: center;
}

// .ads_school {
//   margin: 40px 0px;
//   // height: 120px;
//   width: 84%;
//   //object-fit: cover;
// }

.ads_school_box {
  // height: 150px;
  margin-left: 15px;
  margin-bottom: 30px;
  width: 96%;
  // object-fit: cover;
}

// .loader-container {
//   width: 100%;
//   height: 100vh;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   position: fixed;
//   background: rgba(0, 0, 0, 0.834);
//   z-index: 1;
// }

// .spinner {
//   width: 64px;
//   height: 64px;
//   border: 8px solid;
//   // border-color: #3d5af1 transparent #3d5af1 transparent;
//   border-color: #f13da9 transparent #f13da9 transparent;
//   border-radius: 50%;
//   animation: spin-anim 1.2s linear infinite;
// }

// @keyframes spin-anim {
//   0% {
//       transform: rotate(0deg);
//   }
//   100% {
//       transform: rotate(360deg);
//   }
// }

@media screen and (max-width: 767px) {
  .top_col_content h2 {
    margin-left: 0px !important;
  }
  .sk-topLeftimg-box {
    flex: 100% 0 0;
  }
  .sk-storyHeading-top {
    font-size: 24px;
  }
  .sk-topLeftimg-box img {
    padding: 10px;
    max-width: 160px;
  }
  .sk-serachTop-school input {
    width: 100%;
  }
  .sk-rightlist-school {
    display: block;
    padding-top: 0;
  }
  .mobile_view_city_selct p {
    font-weight: 500;
    margin-bottom: 15px;
  }
  .mobile_view_city_selct ul {
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 10px;
  }
  .mobile_view_city_selct ul:last-child {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.top_col_content h2 {
  margin-left: 1px !important;
}

.loader {
  margin-top: 15%;
  margin-left: 50%;
  color: #ec498a;
}
