$primary-color: #d3317d;
html {
  scroll-behavior: smooth;
}
button {
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  color: #fff;
  background-color: $primary-color;
  cursor: pointer;

  &:hover {
    background-color: darken($primary-color, 10%);
  }

  &:focus {
    outline: none;
    // box-shadow: 0 0 0 3px rgba($primary-color, 0.5);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}


  // * {
  //   scrollbar-width: auto;
  //   scrollbar-color: #e8e8e8 #dbdbdb;
  // }

  // *::-webkit-scrollbar {
  //   width: 6px;
  // }

  // *::-webkit-scrollbar-track {
  //   background: #d9d9d9;
  // }

  // *::-webkit-scrollbar-thumb {
  //   background: #888;
  //   width: 100px;
  //   height: 6px;
  // }