.owl-theme {
  // max-width: 1180px;
  // margin-left: -150px;
  // margin-top: 85px;
  margin-left: -10%;
  // margin-top: 60px;
  min-width: 90%;
  width: auto;
}

a.item {
  text-decoration: none;
}

.set a {
  text-decoration: none;
}

.item {
  color: #ffffff;
  width: 330px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition-duration: 1s;
}

.item:hover {
  margin-top: -10px;
  transition: 1s;
  height: 100%;
  cursor: pointer;
}

.owl-stage-outer {
  position: relative;
  -webkit-transform: translate3d(0px, 0px, 0px);
  overflow-x: clip !important;
  margin-left: 30px;
  .owl-stage{

    transform: translate3d(-11650px, 0px, 0px);
    transition: all 0.8s ease 0s;
    width: 26214px;
    height: 350px;
    align-items: center;
    justify-content: center;
    display: flex;
}
}

.owl-nav {
  position: absolute;
  top: 30%;
  left: -7%;
  color: #fff;
}

.owl-theme .owl-nav [class*="owl-"]:hover {
  background-color: transparent;
}

.owl-nav button span {
  font-size: 35px;
  margin: 0;
  padding: 0 19px;
  border: 1px solid #fff;
  border-radius: 50%;
  width: 44px;
  height: 44px;
  display: none;
}

.abc_box .abc {
  color: #fff;
  position: absolute;
  top: 70%;
  // left: 50%;
  right: 50%;
  font-weight: 500;
  font-size: 18px;

  // transform: translate(-50%, -50%);
}

.abc_box .abc::after {
  content: "";
  border-bottom: 2px solid #fff;
  opacity: 0.5;
  display: block;
  // width: 120px;
  width: 95px;
}

.owl-carousel.owl-theme.owl-loaded.owl-drag a {
  list-style: none;
  text-decoration: none;
}

.owl-carousel .owl-stage-outer {
  overflow: inherit;
  // overflow: hidden;
}

.owl-nav button.owl-prev::before {
  content: "";
  background-image: url("../../assets/icons/arrow_right.png");
  border: 1px solid #dadada;
  width: 44px;
  height: 44px;
  background-size: auto;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 100px;
  padding: 20px;
  left: 0px;
  top: 70px;
  right: 0;
  margin: auto;
}

.owl-nav button.owl-next::before {
  content: "";
  background-image: url("../../assets/icons/arrow_left.png");
  border: 1px solid #dadada;
  width: 44px;
  height: 44px;
  background-size: auto;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 100px;
  padding: 20px;
  left: 0px;
  top: 0px;
  right: 0;
  margin: auto;
}

.owl-carousel .owl-item img {
  max-width: 100%;
  width: auto;
}

.owl-carousel .owl-item img {
  display: inline-block;
  width: auto;
  // padding: 10px 0;
}
.youMayLikeCarousel{
  padding-left: 0;
  padding-right: 0;
}
.YouMayLikeCarouselCarousel--left {
  bottom: 0 !important;
  top: 0;
  margin: auto;
  padding: 0;
  width: auto !important;
  height: auto !important;
}
.YouMayLikeCarouselCarousel--right {
  bottom: 0 !important;
  top: 0;
  right: 20px !important;
  margin: auto !important;
  height: auto !important;
  padding: 0 !important;
  width: 0 !important;
}

// .similar-coursesslider .boxnew {
//   background: #ffffff;
//   border: 1px solid #e3e4eb;
//   box-sizing: border-box;
//   border-radius: 10px;
//   // width: 100%;
//   padding: 10px;
//   overflow: hidden;
//   overflow: hidden;
//   margin-left: 75px;
//   min-width: 345px;
//   height: 250px;
// }

.similar-coursesslider .boxnew .tag_btn p {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  padding: 12px 0px 5px 0px;
  font-family: "Poppins", sans-serif;
  margin: 0;
}

.similar-coursesslider .slide-img1 img {
  width: 234px;
  height: 130px;
  border-radius: 10px 10px 0px 0px;
}

.box {
  width: 100%;
  padding: 10px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 10px;
}

hr.line {
  margin: 0;
  border: 1px solid #e3e4eb;
}

.names {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.names p {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin: 0;

  img {
    width: 36px;
    height: 36px;
    margin-right: 10px;
  }
}

.names span {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 36px;
  color: #ec498a;
}

.lSSlideOuter .lightSlider,
.lSSlideOuter .lSPager {
  background-color: transparent !important;
}

.slide-img {
  height: auto;
  position: relative;
}

.slide-img img {
  /* width: 100% !important; */
  height: 100%;
  box-sizing: border-box;
  text-align: center;
  margin: auto;
  display: block !important;
}

.detail-box {
  width: 100%;
  box-sizing: border-box;
  font-family: calibri;
}

.type {
  display: flex;
  justify-content: space-between;
}

.type .span2 {
  font-weight: 700;
  letter-spacing: 0.5px;
  padding-right: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #f2994a;
  margin: 14px 0;
}

.type .span2 span {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;

  color: #828282;
}

.time {
  display: flex;
  justify-content: space-between;
}

.time p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}

.type span.std {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 40px;
  color: #000000;
}

.type span {
  color: rgba(26, 26, 26, 0.5);
}

.price {
  color: #333333;
  font-weight: 600;
  font-size: 1.1rem;
  letter-spacing: 0.5px;
}

button.view_test_cate {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  background: #ec498a;
  border-radius: 2px;
  padding: 20px 42px;
  border: 1px solid #ffffff;
  text-align: center;
  display: block;
  margin: 50px auto;
  transition-duration: 1s;
  transition-timing-function: linear;
}

button.view_test_cate:hover {
  border: 1px solid #ec498a;
  background-color: transparent;
  color: #ec498a;
  transition: 1s;
}

.courses {
  width: 50%;
  display: flex;
  justify-content: center;
  margin: 25px auto;
  user-select: none;
}

.view_test_cate {
  user-select: none;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  background: #ec498a;
  border-radius: 2px;
  padding: 15px 25px;
  border: 1px solid #ffffff;
  text-align: center;
  display: block;
  margin: 20px auto;
  transition-duration: 1s;
  transition-timing-function: linear;
}

.courses :hover {
  border: 1px solid #ec498a;
  background-color: transparent;
  color: #ec498a;
  user-select: none;
  transition: 1s;
  cursor: pointer;
}

.buy-btn {
  width: 160px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  color: #252525;
  font-weight: 700;
  letter-spacing: 1px;
  font-family: calibri;
  border-radius: 20px;
  box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.2);
}

.buy-btn:hover {
  color: #ffffff;
  background-color: #f15fa3;
  transition: all ease 0.3s;
}

.overlay {
  visibility: hidden;
}

.slide-img:hover .overlay {
  visibility: visible;
  animation: fade 0.5s;
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.lSSlideWrapper {
  position: static !important;
}

.arrow_box {
  width: 37%;
  height: 432px;
  background-color: #ec498a;
  color: #fff;
  float: left;
  z-index: -1;
}

.lSAction > .lSNext {
  background-position: -32px 0;
  left: 212px !important;
  top: 42% !important;
}

.lSAction > .lSPrev {
  left: 216px !important;
}

.lSAction > a {
  top: 36% !important;
  opacity: 1 !important;
}

.tag_btn button.btn.btn-info {
  background: #fedeff;
  border-radius: 100px;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #ef5de9;
  margin-top: 10px;
  margin-bottom: 14px;
}

.tag_btn button.btn.btn-info.two {
  background: #fff6de;
  color: #ef805d;
}

.tag_btn button.btn.btn-info.three {
  background: #eddeff;
  color: #7a5def;
}

.tag_btn h2 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  padding-top: 10px;
  margin: 0;
}

button.btn.btn_Courses {
  width: 190px;
  height: 56px;
  background: #ec498a;
  border-radius: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  margin: 0px auto;
  margin-bottom: 50px;
  display: block;
}

// .owl-carousel .owl-nav.disabled,
// .owl-carousel .owl-dots.disabled {
//   display: block !important;
// }

.collage_name {
  height: 37px;
  left: 102px;
  color: #000000;
  margin: 35px 10px 46px 10px;
  text-align: center;

  h2 {
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }
}

@media screen and (min-device-width: 1919px) and (max-device-width: 1920px) {
  .owl-theme {
    max-width: 1480px;
  }
}

@media screen and (min-device-width: 1270px) and (max-device-width: 1280px) {
  // @media screen and (max-width: 1280px) {
  #myCarousel .carousel-item {
    height: 75vh !important;
  }
}

@media screen and (min-device-width: 50px) and (max-device-width: 767px) {
  .sec2_right {
    padding: 15px !important;
  }

  .sec2_right a.btn_view {
    padding: 23px 80px !important;
    width: 100%;
  }

  .owl-carousel .owl-item img {
    object-fit: contain !important;
    // height: auto;
  }
  // .owl-carousel {
  //   margin-left: -10px !important;
  // }
}

@media screen and (max-width: 1024px) {
  .abc_box {
    width: 35% !important;
  }

  // .owl-carousel.owl-loaded {
  //   width: 80%;
  // }
}

@media screen and (max-width: 768px) {
  .owl-theme {
    margin-left: -101px;
  }

  .owl-stage-outer {
    margin-left: 20px;
  }
}

@media screen and (max-width: 820px) {
  .owl-stage-outer {
    margin-left: 20px;
  }
}

@media screen and (max-width: 1280px) {
  .abc_box {
    width: 300px;
  }

  .owl-theme {
    max-width: 1100px;
    margin-left: -120px;
  }
}

@media screen and (max-width: 1366px) {
  .owl-nav {
    left: -7%;
  }
}
