.loader-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 80px;
  margin: auto;
  width: 100%;
  height: 50vh;

  img {
    height: auto;
  }
}
