.back_to_top {
  position: fixed;
  padding: 7px;
  height: 20px;
  left: auto;
  right: 3%;
  bottom: 5%;
  height: 35px;
  font-size: 3rem;
  z-index: 100;
  color: #fff;
  background-color: #ec498a;
  ;
  border-radius: 5px;
  font-size: 38px;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 182, 193, 0.8);
  }
}
