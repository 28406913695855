@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import "../../Styles/variable";

/*top bar*/
body{
  font-family: 'Poppins', sans-serif !important;
}
.navbar-brand img {
  max-width: 100%;
}

header {
  background: $mainColor;
  box-shadow: 0 3px 10px 0 #00000017;
}

.topbar {
  padding: 7px 0px;
}

.top_bar_btn {
  margin-top: 18px;
}

#custom-search-input {
  margin: 10px 0px 0px 0px;
  padding: 0;
  width: 500px;
}

#custom-search-input .search-query {
  width: 100%;
  padding: 0px 12px;
  margin-bottom: 0;
  background: $mainColor;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  height: 46px;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  padding-right: 50px;
}

#custom-search-input .input-group-btn img {
  padding: 4px;
  background-color: #fff;
  cursor: pointer;
}

#custom-search-input .search-query::-webkit-input-placeholder {
  color: #bdbdbd;
}

#custom-search-input button {
  border: 0;
  background: none;
  padding: 2px 5px;
  margin-top: 2px;
  position: absolute;
  right: 0;
  top: 0px;
  margin-bottom: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  cursor: unset;
  z-index: 999;
}

.d-inline-block {
  display: inline-flex !important;
}

.btn.btn-bg-black {
  background: #000000;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: $mainColor;
  transition: 0.25s ease;
  padding: 9px 23px;
}

.btn.btn-bg-black:hover {
  background: $primaryColor;
}

.login_head {
  box-shadow: none;
}

.login_head .middle_nav_login {
  background: $navbgColor;
  margin: 10px 0px 30px;
}

.navbar-nav {
  width: 1087px;
  justify-content: space-between;
}

.login_head .middle_nav_login .navbar-nav .nav-item {
  margin: 0px 26px 0px;
}

.login_head .middle_nav_login .navbar-nav .nav-item .nav-link {
  font-family: Sora medium;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: $mainColor;
}

.other_head .btn.btn-bg-pink {
  background: #ec498a;
  border: 1px solid #ec498a;
  box-sizing: border-box;
  border-radius: 2px;
  height: 40px;
  padding: 8px 21px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  transition: 0.25s ease;
  transition-timing-function: linear;
}

.other_head .btn.btn-bg-pink:hover {
  background: #fff;
  color: #ec498a;
  transition: 1s;
}

.other_head .MuiButton-text.MuiButton-root {
  background: #fff;
  border: 1px solid #000000;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  height: 38px;
  padding: 8px 21px;
  transition: 0.25s ease;
  transition-timing-function: linear;
}

.sign_in p {
  display: none;
}

.other_head .MuiButton-text.MuiButton-root:hover {
  background: #ec498a;
  color: #fff !important;
  border: none !important;
  transition: 1s;
}

/*Middle navbar*/
.other_head .middle_nav_login .navbar-nav .nav-item {
  margin: 10px 16px 0px 10px;
}

.other_head .middle_nav_login .navbar-nav .nav-item .nav-link {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #d62c79;
  text-transform: capitalize;
  padding: 7px 13px;
  transition: 0.25s ease;
}

.other_head .middle_nav_login .navbar-nav .nav-item .nav-link:hover {
  background: #ec498a;
  color: #fff;
}

.other_head .middle_nav_login .navbar-nav .nav-item.active {
  background: #ec498a;
}

.other_head .middle_nav_login .navbar-nav .nav-item.active .nav-link {
  color: #fff;
}

.guidence_button {
  background: transparent;
  border: 0;
  // margin-top: -10px;
  // margin-bottom: -10px;
}

.toggleDrawer {
  & .MuiPaper-root {
    width: 70%;
  }
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper
  li.active {
  color: #fff;
  background: #e83e8c;
}

@media only screen and (min-width: 992px) and (max-width: 1280px) {
  #custom-search-input {
    margin: 10px 0px 0px 0px;
    padding: 0;
    width: 392px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .other_head .middle_nav_login .navbar-nav .nav-item .nav-link {
    font-size: 0.75rem;
    line-height: 26px;
    padding: 7px 10px;
  }

  .navbar-nav {
    width: 100%;
  }

  .other_head .middle_nav_login .navbar-nav .nav-item {
    margin: 10px 0px 0px 5px;
  }

  #custom-search-input {
    margin: 10px 0px 0px 0px;
    width: 218px;
    vertical-align: middle;
  }

  .top_bar_btn {
    margin: 0px;
  }

  #custom-search-input .search-query::-webkit-input-placeholder {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
  }
}

@media screen and (min-device-width: 50px) and (max-device-width: 767px) {
  // .other_head .btn.btn-bg-pink {
  //   margin-right: 15px;
  // }
  // header {
  //   box-shadow: none;
  // }

  .other_head .MuiButton-text.MuiButton-root:hover {
    background: #ec498a;
    color: #fff !important;
    border: none !important;
    transition: 1s;
  }

  .navbar-collapse {
    overflow: hidden;
    top: 68px;
    position: fixed;
    height: calc(100vh - 30px) !important;
    background: #fff;
    left: -100vw !important;
    width: 100% !important;
    transition: ease all 0.5s;
    padding: 0px;
    margin: 0px;
    display: block !important;
    visibility: hidden;
    z-index: 9999999999999999;
  }

  #custom-search-input {
    width: 100% !important;
  }

  .navbar-collapse.show {
    left: 0vw !important;
    transition: ease all 0.5s;
    visibility: visible;
  }

  .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
    transform: translateY(8px) rotate(-45deg);
  }

  .navbar-light .navbar-toggler-icon:after,
  .navbar-light .navbar-toggler-icon:before {
    width: 24px;
    position: absolute;
    height: 2px;
    background-color: #000;
    top: 0;
    left: 0;
    content: "";
    z-index: 2;
    transition: all 300ms linear;
  }

  .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
    transform: rotate(45deg);
  }

  #custom-search-input {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .navbar-collapse.show {
    left: 0 !important;
    transition: all 0.5s ease-in-out;
    visibility: visible;
  }
}

@media screen and (max-width: 767px) {
  .navbar-collapse {
    overflow: hidden;
    top: 0;
    position: fixed;
    height: calc(100vh - 0px) !important;
    background: #fff;
    left: -100vw !important;
    /* height: 100% !important; */
    width: 100%;
    transition: ease all 0.5s;
    padding: 0px;
    margin: 0px;
    display: block !important;
    visibility: hidden;
    z-index: 999999999;
  }

}

@media screen and (max-width: 767px) {
  .navbar .open.collapse.show {
    left: 0px !important;
    text-align: left;
  }

  .guidence_button {
    margin-top: -10px;
    margin-bottom: -10px;
    // background-color: #000;
    width: 240px;
    text-align: left;
  }
}

@media screen and (max-width: 1080px) {
  .navbar-nav {
    width: 100%;
  }

  .other_head .middle_nav_login .navbar-nav .nav-item {
    margin: 0;
  }
}

.profile-img {
  height: 20px !important;
  width: 20px !important;
  border-radius: 50%;
}

.notificationButton {
  border: none !important;
  // color: #fff;
}

.notificationButton:hover {
  background: #fff !important;
}

.nav-link:hover {
  color: #e83e8c;
  text-decoration: none !important;
}

.limited-text {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 30ch;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}

a.limited-text:hover {
  text-decoration: none !important;
}

.css-tlelie-MuiListItemText-root {
  padding: 3px !important;
  cursor: pointer !important;
}

.css-1p823my-MuiListItem-root {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.css-h4y409-MuiList-root .css-tlelie-MuiListItemText-root:hover {
  background: #ec498a !important;
  color: #fff !important;
  text-decoration: none !important;
}

.active-route {
  background: #ec498a !important;
  color: #fff !important;
}

.css-h4y409-MuiList-root
  .css-tlelie-MuiListItemText-root
  .css-10hburv-MuiTypography-root {
  font-weight: 500 !important;
  font-size: 1.1rem !important;
}
