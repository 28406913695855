$breakpoint-small: 768px;
$breakpoint-medium: 1024px;

.wrapper {
  width: 100%;
  background-color: #222;
}
.sk-footer-menu {
  margin: 15px 0;
}
.footer {
  color: #fff;
  background: #222;
  padding: 50px 0;
  border-bottom: 1px solid #343434;

  @media (max-width: $breakpoint-medium) {
  }

  @media (max-width: $breakpoint-small) {
    flex-direction: column;

    &-section {
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .footer-hr {
    width: 100%;
    border: 1px solid #857e7e;
    // opacity: 6;
  }
  &-section {
    flex-basis: 25%;
    margin-bottom: 20px;

    @media (max-width: $breakpoint-small) {
      flex-basis: 100%;
    }

    &:last-child {
      text-align: left;

      @media (max-width: $breakpoint-small) {
        text-align: center;
      }
    }

    .footer-heading {
      font-size: 20px;
      margin-bottom: 10px;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        margin-bottom: 5px;

        a {
          color: #fff;
          text-decoration: none;
          transition: color 0.3s ease-in-out;

          &:hover {
            color: #d3317d;
          }
        }
      }
    }
  }
}
.copyRight {
  width: 100%;
  display: flex;
  background: #222;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  padding: 20px 15px;
}
.footer .sk-footer-menu ul {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

.sk-footer-menu h5 {
  font-weight: 600;
  font-size: 20px;
  padding-bottom: 10px;
  color: #fff;
}

.footer .sk-footer-menu ul li a {
  font-size: 16px;
  line-height: 32px;
  font-weight: 400;
  color: #fff;
  transition: all 0.3s;
}
.sk-socialMedia-sec {
  border-bottom: 1px solid #343434;
  padding: 50px 0;
}
.footer .sk-footer-menu ul li a:hover {
  color: #d3317d;
  text-decoration: none;
}
.sk-footer-menu p {
  margin-bottom: 0;
}
.sk-footer-menu p a {
  color: #d3317d;
  font-size: 16px;
  display: flex;
  align-items: center;
}
.sk-social-icon ul li img {
  width: 36px;
}
.sk-footer-menu img {
  max-width: 120px;
}
.sk-footerSpace-bottom {
  margin-bottom: 20px;
}

.sk-footer-menu p a svg {
  margin-right: 5px;
  font-size: 24px;
}
.sk-social-icon ul {
  list-style: none;
  display: flex;
  grid-gap: 15px;
  padding: 0;
  margin-bottom: 0;
}

.footer .sk-footer-menu ul li {
  padding: 4px 0;
}
.socialMedia {
  .img {
    &:hover {
      box-shadow: 0px 4px 20px #d3317d;
    }
  }

  .social-media a {
    margin-right: 10px;
    color: #555;
  }
}

@media (max-width: $breakpoint-small) {
  .socialMedia {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  .sk-socialMedia-sec {
  }
  .sk-socialMedia-sec .sk-social-icon {
    margin-bottom: 20px;
  }
  .sk-socialMedia-sec .sk-social-icon {
    margin-bottom: 20px;
    text-align: center;
    justify-content: center;
  }
  .sk-socialMedia-sec .sk-social-icon img {
    display: block;
  }
  .sk-footer-menu h5 {
    padding-bottom: 0;
    font-size: 16px;
  }
  .copyRight {
    justify-content: start;
  }
  .footer .sk-footer-menu ul li a{
    font-size: 14px;
  }
  .footer{
    padding: 30px 0 15px;
  }
  .sk-socialMedia-sec{
    padding: 30px 0;
  }
  .sk-footer-menu p a{
    font-size: 14px;
  }
  .sk-footerSpace-bottom{
    margin-bottom: 10px;
  }
  .sk-footer-menu p a svg{
    font-size: 21px;
  }
  .copyRight{
    font-size: 14px;
  }
}
