body {
  overflow-x: hidden;
  font-family: "Poppins", sans-serif !important;
}

.same_padding {
  padding: 60px 0px;
}

img {
  max-width: 100%;
  height: auto;
}
// Eight may 2023

.sk-homeBanner-sec {
  position: relative;
  background: #f5f5f5;
}
// .sk-homeBanner-sec {}

.sk-full-slide0 {
  background: url(../../assets/images/herosec-img.svg) center center;
  background-size: cover;
  padding: 100px 0px;
}
.sk-full-slide1 {
  background: url(../../assets/images/sliderfull1.jpg) no-repeat right center;
  background-size: cover;
  padding: 150px 0px;
}
.sk-full-slide2 {
  background: url(../../assets/images/sliderfull2.jpg) no-repeat right center;
  background-size: cover;
  padding: 125px 0px 125px;
}
.sk-full-slide3 {
  background: url(../../assets/images/sliderfull3.jpg) no-repeat bottom right;
  background-size: cover;
  padding: 100px 0px;
}
.sk-full-slide4 {
  background: url(../../assets/images/sliderfull4.jpg) no-repeat right center;
  background-size: cover;
  padding: 110px 0px 90px;
}

.first-slide-swiper {
  display: none;
}
.second-slide-swiper {
  display: none;
}
.third-slide-swiper {
  display: none;
}
.four-slider {
  display: none;
}
// .sk-homeBanner-sec .swiper {
//   padding-bottom: 50px;
// }
.sh-bannerContent-top {
  width: 100%;
}
.sk-text-bg {
  background: #202020;
  padding: 80px 0 0px;
}
.sk-leading-women {
  background: #202020 url(../../assets/images/indiawomen.png) no-repeat center;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: end;
  justify-content: center;
  min-height: 500px;

  height: 100%;
}

.sk-leading-women .sk-loadMore {
  background: #fff;
  color: #202020;
}
.sk-storyS-images ul {
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  margin-bottom: 0;
}

.sk-storyS-images ul li {
  box-shadow: 0 4px 10px 0 #5c082a1c;
  border-radius: 2px;
  transition: all 0.5s;
  background: #fff;
}

.sk-storyS-images ul li img {
  object-fit: cover;
  height: 120px;
  object-position: top center;
  width: 100%;
  border-radius: 2px 2px 0 0;
}
.sk-ewoman-title {
  padding: 8px;
}

.sk-storyS-images li .sk-ewoman-title p {
  font-size: 12px;
  color: #d3317d;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.sk-storyS-images li .sk-ewoman-title h6 {
  font-size: 9px;
  margin-bottom: 0;
  color: #434242;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.global_img {
  margin-top: 100px;
}

.global_img img {
  max-width: 100%;
}
.sk-scale-animate:nth-child(1),
.sk-scale-animate:nth-child(3),
.sk-scale-animate:nth-child(6),
.sk-scale-animate:nth-child(8) {
  animation: zoom-in-zoom-out 2.5s alternate infinite linear;
  cursor: pointer;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(0.2);
    opacity: 0.2;
  }
  20% {
    transform: scale(0.2);
    opacity: 0.9;
  }
  30% {
    transform: scale(0.2);
    opacity: 0.9;
  }
  40% {
    transform: scale(0.4);
    opacity: 0.9;
  }
  50% {
    transform: scale(0.2);
    opacity: 0.9;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  80% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}

.sk-scale-animate:nth-child(2),
.sk-scale-animate:nth-child(4),
.sk-scale-animate:nth-child(5),
.sk-scale-animate:nth-child(7) {
  animation: zoom-in-zoom-out1 2.5s alternate infinite linear;
  cursor: pointer;
}

@keyframes zoom-in-zoom-out1 {
  0% {
    transform: scale(0.3);
    opacity: 0;
  }
  0% {
    transform: scale(0.3);
    opacity: 0;
  }
  0% {
    transform: scale(0.3);
    opacity: 0;
  }
  0% {
    transform: scale(0.3);
    opacity: 0;
  }
  10% {
    transform: scale(0.3);
    opacity: 0;
  }
  20% {
    transform: scale(0.3);
    opacity: 0;
  }
  30% {
    transform: scale(0.3);
    opacity: 0.5;
  }
  40% {
    transform: scale(0.4);
    opacity: 0.9;
  }
  50% {
    transform: scale(0.2);
    opacity: 0.9;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  80% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}

.sk-leading-women img {
  opacity: 0.8;
}
.sk-pink-bg {
  background: #d3317d;
  padding: 50px 0;
}
.sk-pink-bg .sk-heading-title h2 {
  color: #fff;
}
.sk-success-story.sk-blog-slider.sk-slide-arrow .swiper-button-next {
  bottom: -10px;
  top: auto;
  right: 45%;
}
.sk-success-story.sk-blog-slider.sk-slide-arrow .swiper-button-prev {
  bottom: -10px;
  top: auto;
  left: 45%;
}
.sk-text-bg .sk-heading-title h2 {
  color: #fff;
}
.sk-text-bg .sk-heading-title p {
  color: #fff;
}
.sh-bannerContent-top h1 {
  font-size: calc(2em + 1.1vw);
  text-transform: capitalize;
  color: #fff;
  font-weight: 600;
}
.sh-bannerContent-top h1 span {
  color: #ec498a;
}
.sh-bannerContent-top p {
  margin: 20px 0 40px;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  padding-right: 50px;
}

.sh-bannerContent-top button {
  color: #fff;
  border-radius: 5px;
  font-weight: 600;
}
.sk-homeBanner-sec .swiper-pagination-bullet {
  text-align: right;
  line-height: 20px;
  font-size: 18px;
  color: #fff;
  opacity: 1;
  background: transparent;
  width: 40px;
  position: relative;
  margin-right: 30px !important;
  font-weight: 500;
}
.sk-banner-navigate {
  position: absolute;
  bottom: 20px;
  top: auto;
}
.sk-banner-navigate:before {
  position: absolute;
  content: "";
  left: 68px;
  width: 1.5px;
  height: 18px;
  background: #fff;
  /* right: 0; */
  margin: 0 auto;
  bottom: 12px;
}
.sk-homeBanner-sec .swiper-pagination-bullet:before {
  content: "";
  position: absolute;
  left: 0;
  height: 20px;
  width: 1.5px;
  background: #fff;
  transform: skewX(-15deg);
}

.sk-homeBanner-sec .swiper-pagination-bullet-active {
  background: transparent;
  color: #ec498a;
  position: relative;
  transition: 0.3s;
  font-size: 28px;
  margin-right: 22px !important;
  font-weight: 500;
}

.sk-homeBanner-sec .swiper-pagination-bullet-active::before {
  content: "";
  position: absolute;
  left: -68px;
  width: 35px;
  height: 1px;
  top: 0px;
  bottom: -10px;
  transform: skewX(0deg);
  margin: auto;
  background: #ec498a;
  right: 0;
}
.sk-homeBanner-sec .swiper-pagination {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: end;
}

.sk-banner-navigate .swiper-button-next:after,
.sk-banner-navigate .swiper-button-prev:after {
  font-size: 0;
}
.sk-banner-navigate .swiper-button-next {
  left: 0;
  top: auto;
  bottom: 0;
}
.sk-banner-navigate .swiper-button-prev {
  right: 0;
  top: auto;
  bottom: 0;
}
.swiper-button-next,
.swiper-button-prev {
  color: #ec498a;
}
// .sk-banner-navigate .swiper-button-next.swiper-button-disabled,
// .sk-banner-navigate .swiper-button-prev.swiper-button-disabled {
//   color: #dbdbdb;
//   opacity: 1;
// }
// .sk-banner-navigate .swiper-button-next:after,
// .sk-banner-navigate .swiper-rtl .swiper-button-prev:after {
//   content: "next";
//   margin-left: -200px;
// }

// .sk-homeBanner-sec .swiper-button-next:after,
// .sk-homeBanner-sec .swiper-button-prev:after {
//   font-size: 0;
// }

// .sk-homeBanner-sec .swiper-button-next {
//   left: 0;
//   top: auto;
//   bottom: 0;
// }
// .sk-homeBanner-sec .swiper-button-prev {
//   right: 0;
//   top: auto;
//   bottom: 0;
// }
// .sk-homeBanner-sec .swiper-button-next,
// .sk-homeBanner-sec .swiper-button-prev {
//   color: #db5789;
//   font-size: 16px;
//   font-weight: 400;
// }
// .sk-homeBanner-sec .swiper-button-next:after,
// .sk-homeBanner-sec .swiper-rtl .swiper-button-prev:after {
//   content: "next";
//   margin-left: -200px;
// }
// .sk-homeBanner-sec .swiper-button-next.swiper-button-disabled,
// .sk-homeBanner-sec .swiper-button-prev.swiper-button-disabled {
//   color: #fff;
//   opacity: 1;
// }

.sk-homeBanner-sec.sk-slide-arrow .swiper-button-next {
  right: 20px;
}
.sk-homeBanner-sec.sk-slide-arrow .swiper-button-prev {
  left: 20px;
}
.sk-time-education ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sk-heading-title {
  text-align: center;
  margin-bottom: 50px;
}
.sk-heading-title h2 {
  font-size: calc(2em + 0.6vw);
  color: #202020;
}
.sk-heading-title p {
  color: #505050;
}
.sk-time-education ul li {
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.sk-innerHover-box .sk-courseboth-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 10px 15px 15px;
}
.sk-addHome-sec {
  padding: 30px 0;
  background: #fff;
}
.sk-time-education ul li svg {
  font-size: 16px;
  vertical-align: top;
}
.sk-course-sec {
  padding: 60px 0;
}
.sk-mockTest-sec {
  padding: 60px 0;
}
.sk-bg-color {
  background: #f4f4f4;
}
.sk-course-img {
  position: relative;
}
.sk-course-box .sk-inner-box {
  opacity: 1;
  transition: all 1.5s;
}
.sk-course-box:hover .sk-inner-box {
  opacity: 0;
}
.sk-course-box {
  border-radius: 8px;
  background: #fff;
  overflow: hidden;
  transition: 0.5s;
  position: relative;
  z-index: 1;
  // height: calc(100% - 0px);
}
.sk-content-point {
  border-top: 1px solid #d9d9d9;
  padding-top: 12px;
}
.sk-content-point ul {
  list-style: none;
  padding: 0;
  overflow: auto;
  height: 130px;
}

.sk-content-point ul li {
  position: relative;
  padding-left: 25px;
  padding-bottom: 5px;
  font-size: 12px;
  color: #555;
}
.sk-smallBox-description {
  margin-bottom: 5px;
}
.sk-content-point ul li:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  transform: rotate(45deg);
  height: 10px;
  width: 6px;
  border-bottom: 2px solid #888;
  border-right: 2px solid #888;
}
.sk-blogHome-sec .card__bottom span:last-child {
  border-left: 0px solid #d9d9d9;
}

.sk-favorite-icon {
  position: relative;
  display: inline-block;
  letter-spacing: 2.5px;
}
.sk-favorite-icon svg {
  position: absolute;
  left: 9.8px;
  top: 5px;
  bottom: 0;
  margin: auto;
  right: auto;
  font-size: 30px;
  color: #d3317d;
}

.sk-userReview-box {
  display: flex;
  gap: 10px;
  position: absolute;
  bottom: 10px;
  /* align-items: center; */
}

.sk-userReview-box span h5 {
  font-size: 18px;
  font-weight: 400;
  color: #202020;
  margin-bottom: 3px;
}

.sk-userReview-box span h6 {
  font-size: 12px;
  font-weight: 400;
  color: #888;
  margin-bottom: 0;
}
.sk-userReview-box span .sk-review-home a {
  color: #f9c836;
  /* font-size: 12px; */
}

.sk-userReview-box span .sk-review-home a svg {
  font-size: 18px;
}

.sk-Blogcard-content {
  padding: 12px 15px 5px;
}
.sk-Blogcard-content h5 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-bottom: 0;
  margin: 10px 0;
}
.sk-Blogcard-content p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 14px;
}
.card .card__bottom:last-child {
  margin-right: 0;
}

.sk-course-box .sk-course-img img {
  width: 100%;
}
.sk-course-box .sk-innerHover-box {
  display: none;
  opacity: 0;
}
.sk-course-box:hover .sk-innerHover-box {
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: auto;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: #fff;
  display: block;
  transition: all 2s linear;
  cursor: auto;
  transition: opacity;
  transition-timing-function: all 0.5s;
  transition-duration: 2s;

  -ms-transition: opacity;
  -ms-transition-timing-function: ease-out;
  -ms-transition-duration: 2s;

  -moz-transition: opacity;
  -moz-transition-timing-function: ease-out;
  -moz-transition-duration: 2s;

  -webkit-transition: opacity;
  -webkit-transition-timing-function: ease-out;
  -webkit-transition-duration: 2s;
}
.sk-course-content {
  padding: 10px 15px 15px;
}
.sk-certificate-box {
  position: absolute;
  top: 19.5px;
  right: 0;
  background: url(../../assets/images/homecertificate.svg) no-repeat center
    center;
  font-size: 10px;
  padding: 7.5px 18px 7.5px 15px;
  color: #202020;
}
.sk-certificate-box svg {
  font-size: 16px;
  vertical-align: text-bottom;
}
.sk-smallBox-heading {
  font-size: 12px;
  color: #434242;
  margin-bottom: 10px;
  display: inline-block;
}
.sk-course-content h6 {
  color: #202020;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.sk-course-content p {
  font-size: 12px;
  color: #555;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.sk-course-content .sk-time-education ul li,
.sk-course-content .sk-time-education ul li svg {
  color: #888;
}

.sk-courseboth-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
// .sk-courseboth-btn {
//   display: flex;
//   justify-content: space-between;
//   align-items: end;
//   gap: 10px;
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   padding: 15px;
// }

.sk-courseboth-btn .sk-course-btn {
  font-size: 12px;
  background: #fff;
  border: 1px solid #888;
  color: #202020;
  border-radius: 4px;
  padding: 7.5px 15px;
  transition: 0.3s;
}

.sk-courseboth-btn .sk-course-btn.sk-courseBg-color {
  background: #db5789;
  color: #fff;
  border: 0;
  padding: 8.5px 20px;
  transition: 0.3s;
}

.sk-courseboth-btn .sk-course-btn.sk-courseBg-color:hover {
  box-shadow: 2px 2px 8px #00000026;
}

.sk-courseboth-btn .sk-course-btn:hover {
  box-shadow: 2px 2px 8px 0 #00000026;
}
.sk-begain-sec {
  background: linear-gradient(90.75deg, #f2d1c7 17.41%, #e7bed4 88.78%);
  padding: 80px 0px;
}
.sk-begain-box {
  background: #fff;
  border-radius: 8px;
  border: 1px solid #dfdfdf80;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px 2px 20px;
  margin: 10px 0;
  transition: 0.3s;
  height: calc(100% - 15px);
  overflow: hidden;
}
.sk-begain-img img {
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1);
  transition: 0.3s;
  max-width: max-content;
}
.sk-eventDatetime-detail ul {
  padding-left: 0;
  list-style: none;
  overflow: auto;
  max-height: 360px;
}
.sk-begain-box:hover .sk-begain-img img {
  -webkit-filter: grayscale(0); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(0);
}
.sk-begain-box:hover {
  border: 1px solid #db5789;
  box-shadow: -5px 5px 11px 0px #db578940;
}
.sk-begain-content h5 {
  margin-bottom: 0;
  color: #202020;
  font-size: 18px;
}
.sk-begain-content h6 {
  color: #434242;
  font-weight: 400;
  padding-bottom: 15px;
  margin-bottom: 0;
  font-size: 14px;
}
.sk-begain-content h5 img {
  padding-left: 10px;
}
.sk-mockTest-sec .sk-course-box {
  border: 1px solid #d9d9d9;
  overflow: hidden;
}
.sk-mockTest-sec .sk-courseboth-btn {
  justify-content: center;
  border-top: 1px solid #d9d9d9;
  padding-top: 15px;
}
.sk-slide-arrow .swiper-button-next:after,
.sk-slide-arrow .swiper-button-prev:after {
  font-size: 16px;
  font-weight: 600;
}
.sk-slide-arrow .swiper-button-next,
.sk-slide-arrow .swiper-button-prev {
  border: 1px solid;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  color: #db5789;
  border-color: #db5789;
}
.sk-slide-arrow .swiper,
.sk-slide-arrow swiper-container {
  position: unset;
  // height: calc(100% - 0px);
}

.sk-slide-arrow .swiper-button-next {
  margin-top: 0px;
  position: absolute;
  top: 50%;
  right: -45px;
  transform: translateY(-50%);
}
.sk-slide-arrow .swiper-button-next.swiper-button-disabled,
.sk-slide-arrow .swiper-button-prev.swiper-button-disabled {
  opacity: 1;
  color: #c0c0c0;
  border-color: #c0c0c0;
}
.sk-slide-arrow .swiper-button-prev {
  position: absolute;
  top: 50%;
  left: -45px;
  transform: translateY(-50%);
  margin-top: 0px;
}

.sk-homeStory-sec {
  padding: 80px 0 60px;
  position: relative;
}
.sk-homeStory-add-space::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 50px;
  background: #222;
}
.sk-story-testmonial {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: end;
}
.sk-ewoman-title p {
  color: #d3317d;
  margin-bottom: 5px;
}
.sk-story-testmonial .sk-story-content:first-child {
  margin-right: -50px;
  border-radius: 4px;
  overflow: hidden;
}
.sk-leading-women .sk-testCourse-btn {
  padding-bottom: 0;
}
.sk-story-testmonial .sk-story-content:last-child {
  margin-left: -80px;
  background: #fff;
  padding: 20px 20px 10px;
  box-shadow: 8px 8px 30px #efe8eb;
  border-radius: 4px;
  margin-bottom: -30px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}
.sk-homeStory-sec .swiper-wrapper {
  margin-bottom: 80px;
}
.sk-story-content h4 {
  font-size: calc(1.11em + 0.6vw);
  font-weight: 500;
  color: #434242;
  line-height: 1.5;
  margin-bottom: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.sk-story-content .sk-ewoman-title {
  padding: 0;
}
.sk-bottom-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #d9d9d9;
  padding-top: 10px;
  flex: 1 0 0;
}

.sk-storySuccess-testmonial span {
  color: #888;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.sk-storySuccess-testmonial svg {
  margin-right: 6px;
  font-size: 16px;
  vertical-align: text-top;
}

.sk-storySuccess-testmonial span:last-child {
  margin-right: 0;
}

.sk-number-q {
  border-top: 1px solid #d9d9d9;
  padding: 20px 0;
}

.sk-number-q ul {
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 0;
}

.sk-number-q ul li {
  font-size: 14px;
  line-height: 30px;
}
.sk-number-q ul li:nth-child(2),
.sk-number-q ul li:nth-child(4),
.sk-number-q ul li:nth-child(6) {
  text-align: right;
}
.sk-storySuccess-testmonial {
  display: flex;
  align-items: center;
  gap: 40px;
}

.sk-readmore-story .sk-storyRead-more {
  font-size: 14px;
  background: transparent;
  padding: 8px 10px;
  color: #ec4a8a;
  transition: 0.3s;
}
.sk-readmore-story .sk-storyRead-more svg {
  font-size: 18px;
  margin-left: 5px;
}
.sk-hireInner-box {
  height: calc(100% - 0px);
}
.sk-readmore-story .sk-storyRead-more:hover {
  background: #ec4a8a;
  color: #fff;
  box-shadow: inset -4.5em 0 0 0 #d3317d, inset 4.5em 0 0 0 #d3317d;
}
.sk-homeStory-sec .sk-success-story .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  margin: 0 6px;
}

.sk-homeStory-sec .sk-success-story .swiper-pagination-bullet-active {
  background: #ec4a8a;
  padding: 0px;
  border: 1px solid #d9d9d9;
  position: relative;
  width: 16px;
  height: 16px;
}
.sk-homeStory-sec .sk-success-story .swiper-pagination-bullet-active:before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border: 2px solid #f4f4f4;
  border-radius: 50px;
}
.sk-homeStory-sec .sk-success-story .swiper-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sk-testCourse-btn {
  padding: 50px 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sk-testCourse-btn .sk-loadMore:hover {
  background: #d3317d;
  color: #fff;
}
.sk-connectUs-btn .sk-loadMore:hover {
  background: #fff;
  color: #020202;
}
.sk-loadMore {
  background-color: #202020;
  padding: 13px 30px;
  border-radius: 8px;
  color: #fff;
  font-weight: 600;
  display: inline-block;
  transition: all 0.3s;
  height: 50px;
  width: max-content;
}
.loadMore {
  background-color: #202020;
  padding: 13px 20px;
  border-radius: 8px;
  color: #fff;
  font-weight: 600;
  display: inline-block;
  transition: all 0.3s;
  height: 50px;
  width: max-content;
}
.sk-hireSotory-innerbox h2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.sk-hire-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: end;
}

.sk-changeBothhire-sec .sk-hire-container .sk-hireStory-box:first-child {
  background: linear-gradient(105.54deg, #e3e3e3 21.33%, #f3f2f2 77.29%);
  height: calc(100% - 0px);
}
.sk-changeBothhire-sec .sk-hire-container .sk-hireStory-box:last-child {
  background: url(../../assets/images/hirebannerright.svg) no-repeat center
    center;
  background-size: cover;
  height: calc(100% - 0px);
}
.sk-hireStory-box {
  display: flex;
  align-items: end;
  position: relative;
  z-index: 9;
  padding: 66px 66px 0;
}

.sk-hireSotory-innerbox {
  flex: 1 0 0;
  padding: 0 0px 80px 0;
}

.sk-vectorImg-inner {
  flex: 180px 0 0;
}
.sk-thireChangescolor-btn .sk-loadMore {
  background: #d3317d;
  transition: 0.3s;
}
.sk-thireChangescolor-btn .sk-loadMore:hover {
  background: #202020;
}
.sk-hireStory-box .sk-hireSotory-innerbox h3 {
  font-size: calc(1.5em + 0.5vw);
}
.sk-hireStory-box .sk-hireSotory-innerbox p {
  padding: 15px 0;
}
.sk-scheme-sec {
  padding: 60px 0;
}
.sk-tabBtn-home ul {
  list-style: none;
  padding: 0;
  position: relative;
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: center;
  flex-wrap: wrap;
}
.sk-tabBtn-home ul li a {
  border: 1px solid;
  padding: 10px 30px;
  display: inline-block;
  border-radius: 50px;
  width: max-content;
}
.sk-tabBtn-home .active-time {
  background: #020202;
  color: #fff !important;
  border-color: transparent;
  cursor: pointer;
}
.sk-tabBtn-home ul li a:hover {
  background: #020202;
  color: #fff !important;
  border-color: transparent;
  cursor: pointer;
  text-decoration: none;
}
.sk-gScheme-box {
  height: calc(100% - 0px);
  position: relative;
  background: #fff;
  box-shadow: 4px 4px 20px 2px #faeff4;
  padding: 20px 30px 20px;
  border-radius: 4px;
  margin-bottom: 20px;
}

// .sk-gScheme-box .sk-gScheme-innerbox {
//   background: #fff;
//   box-shadow: 4px 4px 20px 2px #faeff4;
//   padding: 20px 30px 20px;
//   border-radius: 4px;
// }
.sk-gScheme-mainbox {
  background: #f4f4f4;
}
.sk-gScheme-mainbox .swiper-slide {
  height: auto !important;
  padding: 20px 0;
}
.sk-gScheme-box:empty::before {
  grid-column: 1 / -1; /* Span the entire grid */
  text-align: center;
  margin-bottom: 30px; /* Adjust as needed */
}
.sk-gScheme-box .sk-gScheme-innerbox h5 {
  font-weight: 400;
  color: #434242;
  font-size: 18px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sk-gScheme-box .sk-gScheme-innerbox h4 {
  font-weight: 500;
  color: #434242;
  line-height: 140%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sk-gScheme-box .sk-gScheme-innerbox span {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  padding: 10px;
}
.sk-gScheme-box .sk-gScheme-innerbox span img {
  opacity: 0.1;
  max-width: 36px;
}

.sk-gScheme-box .sk-readmore-story {
  text-align: right;
}
.sk-gScheme-box .sk-readmore-story button {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.sk-blogHome-sec {
  padding: 60px 0;
}
.sk-eventDatetime-detail ul {
  padding-left: 0;
  list-style: none;
}
.sk-eventDatetime-detail ul li {
  display: flex;
  border-bottom: 1px solid #d9d9d9;
  padding: 15px 0;
  cursor: pointer;
}
.sk-eventDatetime-detail ul li .sk-eventDate {
  flex: 60px 0 0;
  max-width: 60px;
}
.sk-eventDatetime-detail ul li .sk-eventTime-detail {
  flex: 1 0 0;
}
.sk-eventDatetime-detail ul li .sk-eventDate h6 {
  font-weight: 400;
  color: #888;
}
.sk-eventDatetime-detail ul li .sk-eventDate h3 {
  color: #101010;
  margin-bottom: 0;
}
.sk-eventDatetime-detail ul li .sk-eventTime-detail h6 {
  font-weight: 400;
  color: #888;
}
.sk-eventDatetime-detail ul li .sk-eventTime-detail h4 {
  color: #434242;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sk-blogHeadinghome-title {
  margin-bottom: 40px;
}
.sk-blog-slider.sk-slide-arrow .swiper-button-next,
.sk-blog-slider.sk-slide-arrow .swiper-button-prev {
  border: 1px solid;
  border-radius: 50%;
  width: 36px;
  height: 36px;
}
.sk-blog-slider.sk-slide-arrow .swiper-button-next {
  top: 0;
  right: 10px;
  transform: translateY(0%);
}
.sk-blog-slider.sk-slide-arrow .swiper-button-prev {
  top: 0;
  right: 0;
  left: 89%;
  transform: translateY(0%);
}
.sk-blog-slider.sk-slide-arrow .swiper-button-next:after,
.sk-blog-slider.sk-slide-arrow .swiper-button-prev:after {
  font-size: 12px;
}
.sk-testmonail-sec {
  padding: 60px 0;
}
.sk-testmonail-sec .swiper-slide {
  height: auto;
}
.sk-testmonail-sec .swiper-wrapper {
  padding: 60px 0 0;
  margin-bottom: 60px;
}

.sk-studentlove-box {
  box-shadow: 4px 4px 20px 2px #faeff4;
  padding: 30px;
  border-radius: 6px;
  position: relative;
  height: calc(100% - 20px);
  margin-bottom: 80px;
}

// .sk-story-testmonial .sk-story-content img {
//   object-fit: cover;
//   height: 260px;
//   object-position: center;
// }
.sk-mockTest-sec .sk-course-img img {
  object-fit: contain;
  // height: 230px;
  object-position: top;
  background: radial-gradient(#ffffff, #ffffff);
}
.sk-studentlove-box .sk-qoutesicon {
  position: absolute;
  top: 0;
  left: 0;
  padding: 8px;
}
.sk-studentlove-box p {
  padding: 30px 15px 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  margin-bottom: 30px;
}

.sk-review-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.sk-review-main .sk-rating-overall h5 {
  color: #202020;
  font-weight: 400;
}

.sk-review-main .sk-rating-overall .sk-view-rating {
  color: #888;
}

.sk-review-main .sk-rating-overall .sk-view-no {
  color: #202020;
  font-size: 18px;
}
.sk-review-main .sk-rating-overall .sk-review-home {
  display: inline-block;
  padding: 0px 10px;
}
.sk-review-main .sk-rating-overall .sk-review-home a {
  color: #f9c836;
  font-size: 16px;
}
.sk-review-main button {
  font-size: 14px;
  color: #fff;
  border-radius: 4px;
}
.tabs {
  display: flex;
  border: 1px solid #d9d9d9;
  border-bottom: 0;
  // max-width: fit-content;
}
.tabs button:hover {
  background: #fff;
}
.tab-link {
  background-color: #fff;
  border: none;
  cursor: pointer;
  border-bottom: 3px solid #fff;
  margin: 15px 20px 0px 20px;
  padding: 0 0 15px 0;
}

.tab-link.active {
  background-color: transparent;
  border-bottom: 3px solid #434242;
}

.tab-content {
  display: none;
  padding: 40px 20px;
  border: 1px solid #ccc;
}

.tab-content.active {
  display: block;
}

.sk-userReview-box span:first-child {
  max-width: 52px;
  border-radius: 50px;
  overflow: hidden;
  height: 52px;
}
.sk-userReview-box span img {
  object-fit: cover;
  height: 60px;
  object-position: center;
  width: 60px;
}

.sk-storyS-images {
  // padding-top: 65px;
  // padding-right: 60px;
}
.four-slider {
  text-align: center;
}
.first-slide-swiper {
  text-align: right;
}
.third-slide-swiper {
  text-align: center;
}
.second-slide-swiper {
  text-align: right;
}
.sk-custom-container-fluid {
  padding-left: 80px !important;
  padding-right: 0 !important;
}
// custom responsive start

@media screen and (min-width: 1451px) and (max-width: 2550px) {
  .sk-full-slide0 {
    padding: 200px 0px 200px;
  }
  .sk-full-slide1 {
    padding: 250px 0px;
  }
  .sk-full-slide2 {
    padding: 225px 0px 225px;
  }
  .sk-full-slide3 {
    padding: 200px 0px 200px;
  }
  .sk-full-slide4 {
    padding: 200px 0px 200px;
  }
}
@media screen and (min-width: 1250px) and (max-width: 1366px) {
  .sk-blog-slider.sk-slide-arrow .swiper-button-prev {
    left: 88%;
  }
  .sk-custom-container-fluid {
    padding-left: 20px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1250px) {
  .sk-gScheme-box ul {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (min-width: 1199px) and (max-width: 1399px) {
  .third-slide-swiper img {
    position: relative;
    top: 50px;
  }
  .second-slide-swiper img {
    position: relative;
    top: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1250px) {
  .sk-full-slide0 {
    padding: 50px 0 50px;
  }
  .sk-full-slide1 {
    background-position-x: 88%;
    // padding: 50px 0 50px;
  }
  .sk-full-slide2 {
    padding: 50px 0 50px;
  }
  .sk-full-slide3 {
    background-position-x: 60%;
    padding: 50px 0 50px;
  }
  .sk-full-slide4 {
    padding: 50px 0 50px;
  }
  .sk-begain-content h5 {
    font-size: 14px;
  }
  .sk-success-story.sk-blog-slider.sk-slide-arrow .swiper-button-prev {
    left: 45%;
  }
  .sk-success-story.sk-blog-slider.sk-slide-arrow .swiper-button-next {
    right: 45%;
  }
}
@media screen and (max-width: 991px) {
  .sk-course-btn .sk-course-btn.sk-courseBg-color {
    padding: 8px 12px;
  }
  .sk-custom-container-fluid {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .sk-storyS-images ul {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
  }
  .sk-storyS-images {
    padding-top: 15px;
  }

  .sk-story-testmonial {
    grid-template-columns: repeat(1, 1fr);
  }
  .sk-story-testmonial .sk-story-content:last-child {
    padding: 20px;
    margin: 0;
  }
  .sk-story-testmonial .sk-story-content:first-child {
    margin: 0;
  }
  .sk-hireStory-box {
    align-items: center;
  }
  .sk-begain-content h5 {
    font-size: 16px;
  }
  .sk-begain-sec {
    padding: 40px 0;
  }
  .sk-hire-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .sk-gScheme-box ul {
    display: flex;
    overflow: auto;
    gap: 15px;
  }
  .sk-blog-slider.sk-slide-arrow .swiper-button-prev {
    left: 82%;
  }
  .sk-gScheme-box ul li {
    flex: 300px 0 0;
    max-width: 250px;
    height: 160px;
    padding: 20px;
    min-width: 250px;
    margin-bottom: 0;
  }
  .first-slide-swiper {
    display: block;
  }
  .second-slide-swiper {
    display: block;
  }
  .third-slide-swiper {
    display: block;
  }
  .four-slider {
    display: block;
  }
  .sk-homeBanner-sec {
    background: url(../../assets/images/herosec-img.svg) center center no-repeat;
    padding: 50px 0;
    background-size: cover;
  }
  .sk-full-slide0 {
    background: none;
    padding: 0px;
  }
  .sk-full-slide1 {
    background: none;
    padding: 0px;
  }
  .sk-full-slide2 {
    background: none;
    padding: 0px;
  }
  .sk-full-slide3 {
    background: none;
    padding: 0px;
  }
  .sk-full-slide4 {
    background: none;
    padding: 0px;
  }
}
@media screen and (min-width: 481px) and (max-width: 991px) {
  .sk-success-story.sk-blog-slider.sk-slide-arrow .swiper-button-prev {
    left: 40%;
  }
  .sk-success-story.sk-blog-slider.sk-slide-arrow .swiper-button-next {
    right: 40%;
  }
}

@media screen and (min-width: 481px) and (max-width: 1050px) {
  .sk-leading-women {
    background-size: 100%;
    min-height: 280px;
  }
  .sk-text-bg {
    padding-top: 50px;
  }
  .sk-leading-women .sk-testCourse-btn {
    padding-bottom: 20px;
  }
}
@media screen and (max-width: 480px) {
  .sk-begain-img img {
    max-width: 140px;
  }
  .sk-begain-content h5 {
    font-size: 13px;
  }
  .sk-begain-content h6 {
    font-size: 12px;
  }
  .sk-begain-content h5 svg {
    font-size: 16px;
  }
  .sk-text-bg {
    padding-top: 40px;
  }
  .sk-banner-navigate {
    bottom: 10px;
  }
  .sk-storyS-images ul li img {
    width: 100%;
    height: 84px;
  }
  .sk-success-story.sk-blog-slider.sk-slide-arrow .swiper-button-prev {
    left: 40%;
  }
  .sk-success-story.sk-blog-slider.sk-slide-arrow .swiper-button-next {
    right: 40%;
  }
  .sk-leading-women {
    min-height: 300px;
    background: #202020 url(../../assets/images/mobileindianwomen.png) no-repeat
      center center;
    background-size: 100%;
  }
  .sk-leading-women .sk-testCourse-btn {
    padding-bottom: 0;
    margin-bottom: -40px;
    z-index: 9;
    position: relative;
  }
  .sk-slide-arrow .swiper-button-next {
    right: 0;
  }
  .sk-blog-slider.sk-slide-arrow .swiper-button-prev {
    left: 77%;
  }
  .sk-studentlove-box p {
    padding: 30px 0px 0px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-bottom: 20px;
  }
  .sk-slide-arrow .swiper-button-prev {
    left: 0;
  }
  .sk-hireSotory-innerbox {
    flex: auto;
  }
  .sk-vectorImg-inner {
    flex: auto;
    text-align: center;
  }
  .sk-hireStory-box {
    padding: 20px 15px 0;
    flex-wrap: wrap;
  }
  .sk-hireSotory-innerbox {
    padding-bottom: 30px;
  }
  .sk-begain-box {
    padding-left: 15px;
    overflow: hidden;
  }
  .sk-readmore-story .sk-storyRead-more {
    font-size: 12px;
    padding: 0px;
  }
  .sk-hire-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .sk-tabBtn-home ul {
    overflow: auto;
    flex-wrap: nowrap;
    justify-content: start;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .sk-tabBtn-home ul::-webkit-scrollbar {
    width: 0px;
    background: none;
    display: none;
  }
  .sk-tabBtn-home ul::-moz-scrollbar {
    display: none;
  }
  .sk-slide-arrow .swiper-button-next {
    right: 5px;
  }
  .sk-slide-arrow .swiper-button-prev {
    left: 5px;
  }
  .sk-slide-arrow .swiper-button-next,
  .sk-slide-arrow .swiper-button-prev {
    height: 30px;
    width: 30px;
    background: #ffffffdc;
  }
  .sk-slide-arrow .swiper-button-next:after,
  .sk-slide-arrow .swiper-button-prev:after {
    font-size: 10px;
  }
  .sk-blog-slider.sk-slide-arrow .swiper-button-next,
  .sk-blog-slider.sk-slide-arrow .swiper-button-prev {
    width: 30px;
    height: 30px;
  }
  .sk-blog-slider.sk-slide-arrow .swiper-button-next:after,
  .sk-blog-slider.sk-slide-arrow .swiper-button-prev::after {
    font-size: 8px;
  }
  .tab-content {
    padding: 20px;
  }
  // .sk-banner-navigate {
  //   display: none;
  // }
  .sk-homeBanner-sec .swiper-pagination {
    display: none;
  }
  .sh-bannerContent-top h1 {
    font-size: 24px;
  }
  // .sk-homeBanner-sec .swiper-slide {
  //   padding-bottom: 80px;
  // }
  .sk-homeBanner-sec {
    padding: 30px 0 0;
  }
  .sh-bannerContent-top p {
    font-size: 14px;
    margin: 10px 0 15px;
  }
  .sh-bannerContent-top button {
    font-weight: 500;
    font-size: 14px;
    padding: 8px 15px;
    height: 40px;
  }
  .sk-loadMore {
    height: 40px;
    font-weight: 500;
    font-size: 14px;
    padding: 8px 15px;
  }
  .sh-bannerContent-top {
    margin-bottom: 15px;
  }
  .sk-heading-title h2 {
    font-size: 24px;
  }
  .sk-tabBtn-home ul li a {
    font-size: 14px;
  }
  .sk-heading-title p {
    font-size: 14px;
  }
  .sk-course-sec {
    padding: 30px 0;
  }
  .sk-heading-title {
    margin-bottom: 25px;
  }
  .sk-testCourse-btn {
    padding-top: 30px;
  }
  .sk-mockTest-sec {
    padding: 30px 0;
  }
  .sk-homeStory-sec {
    padding: 80px 0;
  }
  .sk-courseboth-btn .sk-course-btn.sk-courseBg-color {
    padding: 8px 10px;
  }
  .sk-courseboth-btn .sk-course-btn {
    padding: 8px 10px;
  }
  .sk-gScheme-box ul {
    overflow: auto;
    display: flex;
    gap: 15px;
  }
  .sk-gScheme-box ul::-webkit-scrollbar {
    background: transparent;
    width: 0;
  }
  .sk-gScheme-box ul li h4 {
    font-size: 18px;
  }
  .sk-favorite-icon svg {
    font-size: 18px;
    left: 6px;
    top: 1px;
  }
  .sk-blogHeadinghome-title {
    margin-bottom: 30px;
  }
  .sk-blogHeadinghome-title h3 {
    font-size: 21px;
  }
  .sk-testmonail-sec .swiper-wrapper {
    padding: 40px 0 0;
  }
  .sk-blogSidebar-event {
    position: relative;
  }
  .sk-blogSidebar-event .sk-readmore-story {
    position: absolute;
    top: -10px;
    right: 0;
  }
  .sk-eventDatetime-detail ul li .sk-eventTime-detail h6 {
    font-size: 14px;
  }
  .sk-eventDatetime-detail ul li .sk-eventDate h6 {
    font-size: 14px;
  }
  .sk-eventDatetime-detail ul li .sk-eventDate h3 {
    font-size: 21px;
  }
  .sk-eventDatetime-detail ul li .sk-eventTime-detail h4 {
    font-size: 18px;
  }
  .sk-scheme-sec {
    padding: 30px 0;
  }
  .sk-homeStory-sec .swiper-wrapper {
    margin-bottom: 50px;
  }
  .sk-hireStory-box .sk-hireSotory-innerbox p {
    padding-top: 0;
    font-size: 14px;
  }

  .sk-mockTest-sec .sk-course-img img {
    // height: 140px;
    background: radial-gradient(#c3c3c3, #ffffff);
  }
  .sk-gScheme-box ul li {
    flex: 300px 0 0;
    max-width: 250px;
    height: 160px;
    padding: 20px;
    min-width: 250px;
    margin-bottom: 0;
  }
  .sk-storySuccess-testmonial {
    gap: 10px;
  }
  .sk-story-testmonial .sk-story-content:last-child {
    padding: 10px;
  }
  .sk-gScheme-box ul li h5 {
    font-size: 12px;
  }
  .sk-studentlove-box {
    padding: 15px;
    border-radius: 4px;
  }
  .sk-userReview-box span h5 {
    font-size: 14px;
  }
  .sk-studentlove-box p {
    font-size: 14px;
  }
  .sk-hireSotory-innerbox h2 {
    font-size: 24px;
  }
}

// End

/* carousel */
#myCarousel .carousel-item {
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  transition: -webkit-transform 0.6s ease;
  transition: transform 0.6s ease;
  transition: transform 0.6s ease, -webkit-transform 0.6s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000px;
  perspective: 1000px;
  height: 80vh;
  width: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Poppins", sans-serif;
}

#myCarousel .carousel_item1 {
  background-image: url("../../assets/images/banner/1.png");
}

#myCarousel .carousel_item2 {
  background-image: url("../../assets/images/banner/2.png");
}

#myCarousel .carousel_item3 {
  background-image: url("../../assets/images/banner/3.png");
}

#myCarousel .carousel_item4 {
  background-image: url("../../assets/images/banner/4.png");
}

#myCarousel .carousel-item h4 {
  font-style: normal;
  font-weight: normal;
  text-transform: capitalize;
  font-size: 40px;
  line-height: 120%;
  // max-width: 510px;
  width: 100%;
}

#myCarousel .carousel-item h5 {
  text-transform: capitalize;
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 120%;
  max-width: 510px;
  width: 100%;
  margin: 0;
}

#myCarousel .carousel-item h4 span {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  padding-right: 18%;
  display: block;
}

#myCarousel .carousel-item p {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  padding-right: 18%;
}

.carousel-item.carousel_item1 h2 {
  font-size: 22px;
  text-transform: capitalize;
}

#myCarousel .carousel-item .banner_btn {
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  background: #ec498a;
  border: none;
  border-radius: 2px;
  padding: 17px 50px;
  display: inline-block;
  transition-duration: 1s;
  transition-timing-function: linear;
  height: 56px;
}

#myCarousel .carousel-item .banner_btn:hover {
  text-decoration: none;
  transition: 1s;
  background: #fff;
  color: #ec498a;
}

#myCarousel .carousel-item1 h4 {
  color: #ffffff;
}

#myCarousel .carousel-item1 p {
  color: #ffffff;
}

#myCarousel .carousel-item2 h4 {
  color: #323232;
}

#myCarousel .carousel-item2 p {
  color: #757984;
}

#myCarousel .slick-next,
#myCarousel .slick-prev {
  top: 52%;
}

#myCarousel .slick-next {
  right: 41px;
}

#myCarousel .slick-prev,
#myCarousel .slick-next {
  height: 80px;
  width: 80px;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 100px;
  transition: 0.25s ease;
}

#myCarousel .slick-prev:hover {
  border: 1px solid #ec498a;
}

#myCarousel .slick-next:hover {
  border: 1px solid #ec498a;
}

#myCarousel .slick-prev {
  left: 41px;
}

#myCarousel .slick-prev:before {
  content: "";
  background-image: url("../../assets/icons/left_icon.png");
  border: 1px solid #ec498a;
  width: 60px;
  height: 60px;
  background-size: auto;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 100px;
  padding: 20px;
  left: 0px;
  top: 10px;
  right: 0;
  margin: auto;
}

#myCarousel .slick-prev:hover:before,
#myCarousel .slick-next:hover:before {
  background-color: #ec498a;
  opacity: 1;
}

#myCarousel .slick-prev:hover:before {
  background-image: url("../../assets/icons/arrow_left.png");
  background-repeat: no-repeat;
}

#myCarousel .slick-next:hover:before {
  background-image: url("../../assets/icons/right_icon.png");
  background-repeat: no-repeat;
}

#myCarousel .slick-next:before {
  content: "";
  background-image: url("../../assets/icons/right_icons.png");
  border: 1px solid #ec498a;
  width: 60px;
  height: 60px;
  background-size: auto;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 100px;
  padding: 20px;
  left: 0px;
  top: 10px;
  right: 0;
  margin: auto;
}

#myCarousel .slick-prev,
#myCarousel .slick-next {
  transform: translate(0, -30%);
  z-index: 99;
}

/*resume*/
.resume {
  position: relative;
  background-image: url("../../assets/images/resumebg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 500px;
  padding: 48px 58px;
  margin-bottom: 50px;
}

.resume .btn.btn-resume {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ec498a;
  background: #ffffff;
  // border: 1px solid #3f3838;
  border-radius: 2px;
  transition: 0.25s ease;
  padding: 13px 30px;
  transition-duration: 1s;
  transition-timing-function: linear;
}

.resume h4 {
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 36px;
  line-height: 50px;
  color: #000000;
  margin-top: 120px;
}

.resume .btn.btn-resume:hover {
  color: #ffffff;
  background: #ec498a;
  border-color: #ec498a;
}

.resume .resumeimg {
  position: absolute;
  right: 70px;
  bottom: 0px;
  width: 560px;
}

.resume .resumeimg img {
  max-width: 100%;
}

/*job-opprtunity*/
.job-opprtunity-bg {
  position: relative;
  background-image: url("../../assets/images/joinbg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 85px 79px;
  height: 348px;
  // text-align: center;
}

.job-opprtunity-bg h3 {
  font-weight: 600;
  font-style: normal;
  font-size: 35px;
  line-height: 40px;
  // text-transform: capitalize;
  color: #ffffff;
  margin: 0;
}

.job-opprtunity-bg p {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  margin: 15px 0;
  color: #ffffff;
}

.job-opprtunity-bg .btn.btn-job {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-transform: capitalize;
  color: #323232;
  background: #ffffff;
  border-radius: 20px;
  transition: 0.25s ease;
  padding: 12px 23px;
  transition-duration: 1s;
  transition-timing-function: linear;
}

.job-opprtunity-bg .btn.btn-job:hover {
  color: #ffffff;
  background: #ec498a;
}

.job-opprtunity-bg .jobimg {
  position: absolute;
  right: 0px;
  top: -160px;
}

.job-opprtunity-bg .jobimg img {
  max-width: 100%;
}

/*career development*/
.career_community {
  background: linear-gradient(96.72deg, #fad0c4 6.25%, #e4a8e4 103.39%);
}

.career_community h1 {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 40px;
  text-align: center;
  color: #ffffff;
}

.career_community h2 {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 32px;
  text-align: center;
  color: #ffffff;
}

.career_community p {
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #000000;
}

.community-img img {
  max-width: 100%;
}

.btn.btn-start-course {
  background: #ec498a;
  border-radius: 2px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  transition-duration: 1s !important;
  transition-timing-function: linear !important;
  width: 100%;
  text-align: center;
  height: 56px;
}

.btn.btn-start-course:hover {
  background: #fff;
  color: #ec498a;
  transition: 1s;
}

.cer_img {
  display: none;
}

/*certificate*/
.certificate {
  background: #f8f8f8;
  position: relative;
  background-image: url("../../assets/images/certificate0.jpg");
  background-repeat: no-repeat;
  background-size: 100% 103%;
  height: 550px;
}

.certificate_img img {
  max-width: 100%;
}

.certificate h4 {
  font-weight: bold;
  font-size: 32px;
  color: #020202;
}

.certificate p {
  font-size: 20px;
  line-height: 30px;
  color: #828282;
}

/*highlight student*/
.highlight_student {
  background: linear-gradient(96.72deg, #fad0c4 6.25%, #e4a8e4 103.39%);
  position: relative;
  z-index: 9;
}

.highlight_student h3 {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 35px;
  line-height: 50px;
  display: flex;
  align-items: center;
  color: #fff;
  text-transform: capitalize;
}

.highlig {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.highlight_student p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #595f73;
}

.learn_more {
  background: #ec498a;
  border-radius: 2px;
  font-weight: 600;
  font-size: 14px;
  line-height: 42px;
  color: #ffffff;
  height: 44px;
  width: 160px;
  transition-duration: 1s !important;
  transition-timing-function: linear !important;
  display: inline-block;
  text-align: center;
}

.learn_more:hover {
  background: #fff;
  color: #000;
  text-decoration: none;
  transition: 1s !important;
}

.highlight_student .semicircle-img1 {
  position: absolute;
  top: -15px;
  right: -2px;
}

.highlight_student .semicircle-img2 {
  position: absolute;
  bottom: 0px;
  left: -21px;
}

.highlight_student .circle-img {
  position: absolute;
  bottom: -15px;
  right: -26px;
}

.highlight_student p.para-set {
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  color: #404451;
  position: absolute;
  bottom: -35px;
  right: -10%;
  width: 100%;
  margin: auto;
}

// Testimonial css start

.tes_box {
  background: #ffffff;
  padding: 11px 25px;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  text-align: center;
  width: 100%;
  height: 299px !important;
  position: relative;
}

.tes_box_ankita {
  background: #ffffff;
  padding: 11px 25px;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  text-align: center;
  width: 100%;
  height: 269px !important;
  position: relative;
  margin-top: 20px;
  padding-top: 10px;
}

.tes_box p {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px !important;
  line-height: 19px !important;
  text-align: center;
  text-transform: capitalize;
  padding-top: 10px;
  // color: #bdbdbd;
}

.tes_box_ankita p {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  text-transform: capitalize;
  padding-top: 10px;
  // color: #bdbdbd;
}

.tes_box h2 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px !important;
  line-height: 19px !important;
  text-align: center;
  color: #25243f;
}

.tes_box_ankita h2 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  color: #25243f;
}

.tes_box h6 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px !important;
  line-height: 16px !important;
  text-align: center;
  color: #3e414e;
}

.tes_box_ankita h6 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #3e414e;
}

.tes_box2 {
  background: #ffffff;
  border-radius: 10px;
  width: 203px;
  float: right;
  margin-top: 34px;
}

.tes_box2 h2 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  color: #404451;
  padding: 19px 15px;
  text-transform: capitalize;
}

.tes_box3 {
  width: 145px;
  height: 131px;
  text-align: center;
  padding: 24px 12px 22px;
  background: #ffffff;
  border-radius: 9px;
  position: relative;
}

.tes_box.tes-before::before {
  content: "";
  background-image: url("../../assets/images/testimonial/greensemi.png");
  background-repeat: no-repeat;
  width: 65px;
  height: 61px;
  position: absolute;
  left: -30px;
  bottom: -30px;
  z-index: -1;
}

.tes_box3::before {
  content: "";
  position: absolute;
  right: -50px;
  top: -20px;
  background-image: url("../../assets/images/testimonial/orangesemi.png");
  background-repeat: no-repeat;
  width: 65px;
  height: 61px;
  z-index: -1;
}

.tes_box.heg_cha::before {
  content: "";
  width: 55px;
  height: 55px;
  background: #edc988;
  border-radius: 50%;
  position: absolute;
  right: -20px;
  bottom: -20px;
  z-index: -1;
}

.tes_box_ankita.heg_cha::before {
  content: "";
  width: 55px;
  height: 55px;
  background: #edc988;
  border-radius: 50%;
  position: absolute;
  right: -20px;
  bottom: -20px;
  z-index: -1;
}

.tes_box3 h2 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  margin: 0;
  color: #444851;
}

.tes_box3 h6 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #444851;
  text-transform: capitalize;
}

ul.star li {
  display: inline-block;
  list-style: none;
  padding-left: 3px;
}

ul.star {
  padding: 0;
  margin-bottom: 5px;
}

.tes_box.heg_cha {
  margin-top: 22px;
  height: 280px !important;
}

.tes_box_ankita.heg_cha {
  margin-top: 22px;
  height: auto !important;
}

.set {
  display: flex;
}

.abc_box {
  // width: 585px;
  height: 432px;
  background: #ec498a;
  position: relative;
  font-family: Sora;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 52px;
  text-transform: capitalize;
  color: #ffffff;
  min-width: 20%;
}

.container.Our_text p {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #fbada2;
  margin: 0;
}

.certif_con {
  margin: 30px 0px;
}

.container.Our_text h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 52px;
  text-transform: capitalize;
  color: rgb(50 50 50);
  margin-bottom: 30px;
}

.career_community {
  position: relative;
  // padding: 30px 0px;
  // background: linear-gradient(96.72deg, #FAD0C4 6.25%, #E4A8E4 103.39%);
  // height: auto;
}

.career_community img:hover {
  transform: scale(1.09);
  transition: 1s;
}

.career_community img {
  max-width: 100%;
  transition-duration: 1s;
}

.career_community .community1 {
  position: absolute;
  right: 23%;
  top: 146px;
}

.career_community .community2 {
  position: absolute;
  left: 41%;
  top: 50px;
}

.career_community .community3 {
  position: absolute;
  left: 43%;
  top: 261px;
}

.career_community .community4 {
  position: absolute;
  left: 24.5%;
  top: 192px;
}

.career_community .community5 {
  position: absolute;
  right: 25%;
  top: 352px;
}

.career_community .community6 {
  position: absolute;
  right: 13%;
  top: 44px;
}

.career_community .community7 {
  position: absolute;
  right: 4%;
  top: 120px;
}

.career_community .community8 {
  position: absolute;
  right: 0%;
  top: 245px;
}

.career_community .community9 {
  position: absolute;
  top: 374px;
  right: 15%;
}

.career_community .community10 {
  position: absolute;
  right: 9%;
  top: 211px;
}

.career_community .community11 {
  position: absolute;
  right: 4%;
  top: 362px;
}

.career_community .community12 {
  position: absolute;
  top: 330px;
  left: 5%;
}

.career_community .community13 {
  position: absolute;
  left: 26%;
  top: 30px;
}

.career_community .community14 {
  position: absolute;
  left: 0%;
  top: 190px;
}

.career_community .community15 {
  position: absolute;
  left: 5%;
  top: 79px;
}

.career_community .community16 {
  position: absolute;
  left: 15%;
  top: 57px;
}

.career_community .community17 {
  position: absolute;
  left: 10%;
  top: 180px;
}

.career_community .community18 {
  position: absolute;
  right: 30.5%;
  top: 42px;
}

.career_community .community19 {
  position: absolute;
  left: 33%;
  top: 400px;
}

.career_community .community20 {
  position: absolute;
  top: 350px;
  left: 14.5%;
}

.career_community .community1:hover,
.career_community .community2:hover,
.career_community .community3:hover,
.career_community .community4:hover,
.career_community .community5:hover,
.career_community .community6:hover,
.career_community .community7:hover,
.career_community .community8:hover,
.career_community .community9:hover,
.career_community .community10:hover,
.career_community .community11:hover,
.career_community .community12:hover,
.career_community .community13:hover,
.career_community .community14:hover,
.career_community .community15:hover,
.career_community .community16:hover,
.career_community .community17:hover,
.career_community .community18:hover,
.career_community .community19:hover,
.career_community .community20:hover {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  width: auto;
  background: #fff;
  box-shadow: 0 0 0px 0px rgba(233, 0, 0, 0.5);
  border-radius: 50%;
  transition: 250ms color;
  animation-name: wave;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes wave {
  0% {
    box-shadow: 0 0 0px 0px #ec498a;
  }

  25% {
    box-shadow: 0 0 0px 0px rgb(236 73 138 / 25%);
  }

  50% {
    box-shadow: 0 0 0px 0px rgb(236 73 138 / 50%);
  }

  100% {
    box-shadow: 0 0 0px 20px rgb(236 73 138 / 0%);
  }
}

// @mixin rings($duration, $delay) {
//   opacity: 0;
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;
//   position: absolute;
//   top: -8px;
//   left: -8px;
//   right: 0;
//   bottom: 0;
//   content: '';
//   height: 100%;
//   width: 100%;
//   border: 8px solid rgba(0,0,0,0.2);
//   border-radius: 100%;
//   animation-name: ripple;
//   animation-duration: $duration;
//   animation-delay: $delay;
//   animation-iteration-count: infinite;
//   animation-timing-function: cubic-bezier(.65,0,.34,1);
//   z-index: -1;
// }

// .communityring{
//   &::after {
//     @include rings(3s, 0s);
//   }

//   &::before {
//     @include rings(3s, 0.5s);
//   }
// }

// @keyframes wave {
// from {
//   opacity: 1;
//   transform: scale3d(0.75,0.75,1);
// }

// to {
//   opacity: 0;
//   transform: scale3d(1.5,1.5,1);
// }
// }

.mob_comunity_img {
  display: none;
}

@media screen and (min-device-width: 50px) and (max-device-width: 767px) {
  body {
    overflow-x: hidden;
  }

  .tes_box_ankita p {
    font-size: 10px !important;
    line-height: 11px !important;
    margin: 0;
  }

  .mob_comunity_img {
    display: block;
  }

  .community-img.mt-5 {
    display: none;
  }

  .set {
    display: flex;
    justify-content: flex-end;
  }

  .item {
    width: 100%;
  }

  .tes_box.heg_cha::before {
    right: -15px;
  }

  .tes_box_ankita.heg_cha::before {
    right: -15px;
  }

  section.highlight_student.same_padding {
    overflow: hidden;
  }

  #myCarousel .carousel-item {
    height: 655px;
  }

  .resume h4 {
    line-height: 40px;
  }

  .bottom-footer.mt-5 img {
    max-width: 100%;
  }

  .bottom-footer p {
    margin: 0;
  }

  .highlight_student h3 {
    font-size: 28px;
    line-height: 40px;
  }

  #myCarousel .carousel-item h4 {
    font-size: 30px;
  }

  #myCarousel .carousel-item p {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 110%;
    color: #706d6d;
  }

  #myCarousel .carousel-item h4 span {
    font-size: 30px;
  }

  .container.Our_text h2 {
    font-size: 30px;
  }

  .owl-item.active {
    margin: 0px 0 0 20px !important;
    display: block !important;
  }

  .owl-nav button.owl-prev::before {
    display: none;
  }

  .owl-nav button.owl-next::before {
    display: none;
  }

  .job-opprtunity-bg {
    height: auto;
    padding: 30px 15px 0px;
    background-image: none;
    background-image: url("../../assets/images/Mob_joinbg.png");
  }

  .job-opprtunity-bg h3 {
    font-size: 24px;
    line-height: 32px;
  }

  .career_community h1 {
    font-size: 24px;
  }

  .certificate {
    background-image: none;
    height: auto;
    position: relative;
  }

  section.career_community.same_padding.mt-5 {
    padding: 35px 0px;
  }

  .certificate h4 {
    font-size: 28px;
  }

  .job-opprtunity-bg .jobimg {
    position: initial;
  }

  .abc_box .abc {
    display: none;
  }

  .resume h4 {
    margin-top: 50px;
    font-size: 20px !important;
  }

  .job-opprtunity-bg {
    padding: 20px 15px 0 15px !important;
  }

  .resume {
    height: 580px;
    padding: 0;
    background: linear-gradient(96.72deg, #fad0c4 6.25%, #e4a8e4 103.39%);
    text-align: center;
  }

  .resume .resumeimg {
    width: 100%;
    right: 0px;
  }

  #myCarousel .slick-prev:before {
    display: none;
  }

  #myCarousel .slick-next:before {
    display: none;
  }

  #myCarousel .slick-prev:hover {
    border: none;
  }

  #myCarousel .slick-next:hover {
    border: none;
  }

  #myCarousel .carousel_item1 {
    // background-image: url("../../assets/images/banner/mob1.jpg");
    position: relative;
  }

  .row.h_set {
    position: absolute;
    bottom: 70px;
    width: 100%;
  }

  #myCarousel .carousel_item1 {
    background-image: url("../../assets/images/banner/mob1.jpg");
    position: relative;
  }

  #myCarousel .carousel_item2 {
    background-image: url("../../assets/images/banner/mob2.png");
    position: relative;
  }

  #myCarousel .carousel_item3 {
    background-image: url("../../assets/images/banner/mob3.png");
    position: relative;
  }

  #myCarousel .carousel_item4 {
    background-image: url("../../assets/images/banner/mob4.png");
    position: relative;
  }

  .cer_img {
    display: block;
  }

  .cer_img img {
    max-width: 100%;
  }

  section.certificate.same_padding {
    padding: 40px 0px;
  }

  #myCarousel .carousel-item .banner_btn {
    margin: 0 auto;
    display: block;
    width: 330px;
  }

  #myCarousel .carousel-item h5 {
    font-size: 28px;
  }

  .job-opprtunity-bg p {
    font-size: 14px;
    line-height: 19px;
    margin: 0;
  }

  .job-opprtunity-bg .btn.btn-job {
    border-radius: 2px;
    margin-top: 20px;
    margin-bottom: 64px;
  }

  .career_community p {
    font-size: 14px;
    line-height: 19px;
    color: #393d48;
  }

  .tes_box {
    height: auto !important;
    padding: 5px 10px !important;
  }

  .tes_box p {
    font-size: 10px !important;
    line-height: 11px !important;
    margin: 0;
  }

  .tes_box3 {
    width: 145px;
    height: auto;
    padding: 12px 12px 1px;
  }

  .tes_box3 h2 {
    font-size: 10px;
    line-height: 13px;
  }

  .tes_box3 h6 {
    font-size: 8px;
    line-height: 11px;
  }

  .tes_box.heg_cha {
    margin-top: 22px;
    height: auto;
  }

  .tes_box_ankita.heg_cha {
    margin-top: 22px;
    height: auto !important;
  }

  .tes_box_ankita p {
    font-size: 10px !important;
    line-height: 11px !important;
    margin: 0;
  }

  .tes_box2 {
    width: 100%;
  }

  .tes_box2 h2 {
    font-size: 10px;
    line-height: 13px;
    margin: 0;
  }

  .tes_box h2 {
    font-size: 10px;
    line-height: 19px;
    margin: 0;
  }

  .tes_box h6 {
    font-size: 10px !important;
    line-height: 16px !important;
  }

  .tes_box.heg_cha::before {
    right: -50px;
    bottom: -20px;
  }

  .tes_box_ankita.heg_cha::before {
    right: -50px;
    bottom: -20px;
  }

  .tes_box.heg_cha::before {
    right: -15px;
  }

  .tes_box_ankita.heg_cha::before {
    right: -15px;
  }

  .tes_box.tes-before::before {
    background-size: cover;
    width: 35px;
    height: 33px;
    left: -15px;
    bottom: -20px;
  }

  .google_add {
    width: 100%;
    padding: 0px !important;
  }

  .same_padding {
    padding-bottom: 25px;
  }
}

.goverment-schemes {
  text-decoration: none;
  color: white;
  // font-family: "Poppins", sans-serif;
  // font-style: normal;
  // font-weight: bold;
  // font-size: 14px;
  // line-height: 19px;
  // color: #ffffff;
  // background: #ec498a;
  // border-radius: 2px;
  // padding: 20px 42px;
  // border: 1px solid #ffffff;
  // text-align: center;
  // display: block;
  // margin: 50px auto;
  // transition-duration: 1s;
  // transition-timing-function: linear;
}

.goverment-schemes:hover {
  color: #000;
}

@media only screen and (max-width: 1280px) {
  .box {
    height: auto;
    margin-right: 30px;
  }

  .owl-nav {
    left: -70px;
  }

  // #myCarousel .carousel-item {
  //   height: 100vh;
  // }
  #myCarousel .slick-prev {
    left: 5px;
  }

  #myCarousel .slick-next {
    right: 5px;
  }

  .resume h4 {
    max-width: 610px;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 35px;
    display: flex;
    align-items: center;
    text-align: left;
    color: #333333;
  }

  // #myCarousel .carousel-item {
  //   height: 73vh;
  // }
}

@media screen and (max-width: 1920px) {
  .certificate {
    background-size: contain;
  }
}

@media screen and (max-width: 1080px) {
  // #myCarousel .carousel-item {
  //   height: 60vh;
  // }
}

// @media screen and (min-device-width: 1400px) and (max-device-width:1440px) {
//   #myCarousel .carousel-item{
//     height: 62vh;
//   }
// }
// @media screen and (min-device-width: 1200px) and (max-device-width:1300px) {
//   #myCarousel .carousel-item{
//     height: 55vh;
//   }
// }
.community-img.mt-5 {
  height: 550px;
  position: relative;
}

@media screen and (min-device-width: 50px) and (max-device-width: 767px) {
  #myCarousel .carousel-item .banner_btn {
    padding: 17px 10px;
  }

  .google_ads_home {
    // margin: 5px 0px !important;
    // height: 100px !important;
    // width: 100% !important;
    // object-fit: contain;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  #myCarousel .carousel-item {
    height: 30vh;
  }

  #myCarousel .slick-prev {
    display: none !important;
  }

  #myCarousel .slick-next {
    display: none !important;
  }

  .resume .resumeimg {
    right: 0;
    width: 430px;
  }

  #myCarousel .carousel-item h4 {
    font-size: 21px;
  }

  #myCarousel .carousel-item h5 {
    font-size: 24px !important;
  }

  #myCarousel .carousel-item p {
    font-size: 14px !important;
  }

  #myCarousel .carousel-item .banner_btn {
    font-size: 1rem !important;
    height: 40px !important;
    padding: 10px 24px !important;
    margin-top: -20px;
  }

  .certificate {
    background-image: none;
  }

  .cer_img {
    display: block;
  }

  .cer_img img {
    max-width: 100%;
  }

  .mob_comunity_img {
    display: block;
  }

  .community-img.mt-5 {
    display: none;
  }

  .job-opprtunity-bg .jobimg {
    top: -65px;
    display: none;
  }

  .tes_box {
    height: auto !important;
  }

  .tes_box2 {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  // .job-opprtunity-bg .jobimg {
  //   display: none;
  // }
  .job-opprtunity-bg {
    padding: 35px 79px;
  }

  .highlight_student h3 {
    font-size: 28px;
    line-height: 40px;
  }

  .row.h_set {
    margin-top: 1rem !important;
  }

  .row.h_set h5 {
    font-size: 1.5rem !important;
  }

  .row.h_set a {
    margin-bottom: 1rem !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1300px) {
  .resume .resumeimg {
    right: 45px;
    bottom: 0px;
    width: 550px;
  }
}

@media screen and (min-device-width: 1420px) and (max-device-width: 1440px) {
  #myCarousel .carousel-item {
    height: 85vh !important;
  }
}

.mock_test_pop.MuiBox-root {
  background: #ffffff;
  border-radius: 10px;
  width: 80%;
  height: 230px;
  text-align: center;
  border: none;
  position: relative;
}

.mock_test_pop.MuiBox-root h2 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 120%;
  text-align: center;
  text-transform: capitalize;
  color: #323232;
  margin: 21px auto;
}

.mock_test_pop.MuiBox-root button.btn.btn-info {
  background: #ec498a;
  border: none;
  font-family: Roboto;
  width: 115px;
  height: 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
}

.mock_test_pop.MuiBox-root img.close_img {
  position: absolute;
  right: 15px;
  top: 15px;
}

.ads_home_cover {
  text-align: center;
}

.google_ads_home {
  // margin: 5px 0px;
  // height: 150px;
  // width: 84%;
  // object-fit: contain;
}

.NikitaSharmaImage {
  height: 100px;
  width: 100px;
  border-radius: 50%;
}

.AnkitaSharmaImage {
  height: 100px;
  width: 100px;
  border-radius: 50%;
}
