.login_form span.MuiTabs-indicator {
  border-bottom: none !important;
}
.login_form .MuiTabs-flexContainer {
  justify-content: space-between;
  margin-top: 20px;
}
.login_form .MuiTabPanel-root {
  padding: 24px 0 !important;
}

.login_form .MuiTabs-indicator {
  position: absolute;
  height: 2px;
  bottom: 0;
  width: 100%;
  -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: transparent;
}
.login_form .MuiTab-root.Mui-selected {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  color: #425466;
  padding: 0;
  background: #ffffff;
  border: 1px solid #d62c79;
  box-sizing: border-box;
  border-radius: 5px;
  width: 48%;
  height: 42px;
}
.login_form .xyz {
  background: #ffffff;
  border: 1px solid #e6e5eb;
  box-sizing: border-box;
  border-radius: 5px;
  width: 48%;
  height: 44px;
}
.login_form .MuiButtonBase-root.MuiTab-root {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  color: #425466;
  padding: 0;
}
.login_form .MuiOutlinedInput-root {
  height: 40px;
  padding: 0px 0 0px 6px;
}
.login_form .MuiOutlinedInput-notchedOutline {
  border: none;
}
.login_form .MuiOutlinedInput-input {
  padding-left: 9px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}