body {
  font-family: "Poppins", sans-serif !important;
}
.Navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // background: #ffffff;
  // padding: 10px 0;
  // box-shadow: 0 2px 15px 0px #edd7e175;
}
.sk-header-bg {
  padding: 15px 0;
  position: sticky;
  top: 58px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
}

.sk-headerTop-bg {
  background: #f0f0f0;
  position: sticky;
  top: 0;
  z-index: 999;
}
.sk-mobile-hide {
  margin-left: 15px;
  cursor: pointer;
}
.Navbar .custom-dropdown button {
  font-size: 16px;
  color: #020202;
  transition: all 0.3s;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 22.5px;
}
.profile .rs-dropdown {
  position: unset;
}
.rs-dropdown .rs-btn > .rs-dropdown-toggle-caret {
  top: 0;
  bottom: 0;
  margin: auto;
}
.Navbar .custom-dropdown button:hover {
  color: #d3317d !important;
}
.Navbar > .nav-items > a {
  color: #020202;
  font-size: 16px;
  text-decoration: none;
  margin: 10px 12px;
  position: relative;
  transition: all 0.3s;
  cursor: pointer;
}
.active {
  color: #d3317d !important;
}
.Navbar > .nav-items > a:hover {
  color: #d3317d;
}

// .Navbar > .nav-items > a::before {
//   content: "";
//   position: absolute;
//   left: 0;
//   bottom: 0;
//   width: 0;
//   height: 2px;
//   background: #d3317d;
//   transition: all 0.45s;
// }

// .Navbar > .nav-items > a:hover::before {
//   width: 100%;
// }

.Navbar > .nav-toggle {
  display: none;
}
#subMenu-mobile {
  display: none;
}
@media (max-width: 991px) {
  .Navbar > .nav-items {
    position: fixed;
    top: 60px;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    transition: all 0.45s;
  }
  .search {
    display: none;
  }

  .Navbar > .nav-items > a::before {
    background: transparent;
  }

  .Navbar > .nav-items.open {
    transform: translateX(0);
    z-index: 9;
    height: 100vh;
    overflow: scroll;
  }

  .Navbar > .nav-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .nav-toggle > .bar {
    position: relative;
    width: 32px;
    height: 2px;
    background: #ffffff;
    transition: all 0.45s ease-in-out;
  }
  .Navbar > .nav-items > a::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 2px;
    background: #d3317d;
    transition: all 0.45s;
  }

  .nav-toggle > .bar::before,
  .nav-toggle > .bar::after {
    content: "";
    position: absolute;
    height: 2px;
    background: #d3317d;
    border-radius: 2px;
    transition: all 0.45s ease-in-out;
  }

  .nav-toggle > .bar::before {
    width: 25px;
    transform: translateY(-8px);
    right: 0;
  }

  .nav-toggle > .bar::after {
    width: 32px;
    transform: translateY(8px);
  }

  .nav-toggle.open > .bar {
    transform: translateX(-40px);
    background: transparent;
  }

  .nav-toggle.open > .bar::before {
    width: 32px;
    transform: rotate(45deg) translate(26px, -26px);
  }

  .nav-toggle.open > .bar::after {
    transform: rotate(-45deg) translate(26px, 26px);
  }
  .subMenu-items {
    display: none;
  }
  #subMenu-mobile {
    display: flex;
  }
}
.nav-logo img {
  max-width: 154px;
  cursor: pointer;
}
.header-top button {
  color: #020202;
}
.header-top .rs-dropdown-toggle-caret {
  right: 5px;
}
.header-top {
  display: flex;
  justify-content: flex-end;
  // background-color: #f0f0f0;
  align-items: center;
  padding: 10px 0px;
  nav {
    ul {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
      align-items: center;
      // flex-wrap: wrap;
      li {
        margin-left: 20px;

        &:first-child {
          margin-left: 0;
        }

        a {
          color: #020202;
          text-decoration: none;
          font-size: 14px;

          &:hover {
            color: #d3317d;
          }
        }
      }
    }
  }
  .mobile {
    margin-right: 10px;
  }
  .sk-header-btn {
    background: #d3317d;
    color: #fff;
    padding: 8.5px 15px;
    border-radius: 2px;
    transition: 0.3s;
    @media screen and (max-width: 480px) {
      padding: 8px;
      font-size: 12px;
    }
    @media screen and (max-width: 360px) {
      padding: 8px 5px;
      font-size: 10px;
    }
  }
  .header-top nav .custom-dropdownl li {
    margin-left: 0;
  }
  .header-top .custom-dropdown .rs-dropdown-item {
    margin-left: 0;
  }
  .sk-header-btn:hover {
    background: #020202;
  }
  .header-top .rs-btn:focus,
  .header-top .rs-btn:hover {
    background: transparent;
    color: #020202;
  }
  .header-top .rs-dropdown-toggle,
  .header-top .rs-dropdown-toggle.rs-btn {
    background: transparent !important;
    color: #020202;
  }
  .custom-dropdown button {
    color: #020202;
  }
  .Navbar .nav-items .custom-dropdown button.rs-btn {
    font-size: 16px !important;
    color: #020202 !important;
  }

  @media (max-width: 991px) {
    width: 100%;
    display: flex;
    justify-content: space-between;

    li {
      margin-left: 0;
    }
    .mobile {
      display: none;
    }
  }
}

.profile {
  display: flex;
  position: relative;
  align-items: center;
  img {
    width: 10px;
    height: 10px;
  }
}

//RSUITE STYELS

.custom-dropdown {
  box-shadow: none;
  border: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #020202;
  // overflow: hidden;
  .rs-dropdown-menu {
    background-color: #f4f4f4;
    z-index: 999 !important;
    box-shadow: 4px 6px 10px 0px #3a071b1c !important;
    border-radius: 0 0 8px 8px;
    flex-wrap: wrap;
  }

  .rs-dropdown-item {
    font-weight: 400;
    font-size: 14px;
    margin-left: 0;
    @media (max-width: 991px) {
      font-size: 12px;
      width: 100%;
    }
    &:hover {
      color: #db5789;
      background: #fff;
      display: inline-block;
      width: 100%;
    }

    &.active {
      background-color: #fff;
      color: #db5789;
      width: 100%;
    }
  }
}
.searchIconSmall {
  max-width: 19.5px;
}
.mobile-show {
  display: none;
  @media (max-width: 991px) {
    display: flex;
    // z-index: 9;
    position: relative;
    align-items: center;
  }
}

@media screen and (max-width: 1250px) {
  // .Navbar{
  //   height: 60px;
  //   position: sticky;
  //   top: 0;
  //   bottom: 0;
  //   left: 0;
  //   right: 0;
  //   padding: 10px 0px;
  //   z-index: 99;
  // }
  .sk-header-bg {
    // height: 60px;
    position: sticky;
    // top: 0;
    // bottom: 0;
    left: 0;
    right: 0;
    // padding: 10px 0px;
    z-index: 99;
  }
  .Navbar > .nav-items > a {
    font-size: 11px;
    margin-left: 15px;
    margin-right: 15px;
  }
  .Navbar .custom-dropdown button {
    font-size: 11px;
    padding-left: 15px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .rs-dropdown .rs-btn > .rs-dropdown-toggle-caret {
    font-size: 10px;
  }
  .header-top {
    padding: 10px 0px;
    position: relative;
    transition: 0.5s;
    z-index: 999;
  }
  .rs-dropdown-toggle-caret {
    font-size: 11px;
  }
  .nav-logo img {
    max-width: 120px;
  }
}

@media (max-width: 991px) {
  .header-top nav ul li {
    margin-left: 0 !important;
  }
  .sk-headerTop-bg {
    background: #202020;
    position: sticky;
    top: 0;
    z-index: 999;
  }
  .header-top .custom-dropdown button {
    color: #fff;
  }
  .header-top button {
    color: #fff !important;
    border-color: #434242 !important;
    font-size: 12px !important;
  }
  .header-top nav ul {
    justify-content: space-between;
  }
  .header-top nav {
    width: 100%;
  }
  .sk-mobile-hide {
    display: none;
  }
}
@media screen and (max-width: 360px) {
  .header-top button {
    font-size: 10px !important;
  }
  .custom-notification-icon {
    padding-right: 2px !important;
  }
  .custom-notification-icon svg {
    font-size: 14px !important;
  }
}
