.custom-notification-icon {
  display: inline-flex;
  align-items: center;
  padding-right: 5px;
  vertical-align: middle;
}
.custom-notification-icon svg {
  font-size: 18px;
}
@keyframes bellRing {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(20deg);
  }
  50% {
    transform: rotate(-20deg);
  }
  75% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(0);
  }
}

.bell {
  transform-origin: top center;
  animation-name: bellRing;
  animation-duration: 0.8s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.notification-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.notification-list li {
  padding: 10px;
  border-bottom: 1px solid #e1e1e1;
}

.notification-list li:last-child {
  border-bottom: none;
}

.notification-link {
  color: #333;
  text-decoration: none;
}

.notification-link:hover {
  background-color: #f2f2f2;
}

.custom-button {
  background-color: #202020;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  text-decoration: none;
  transition: background-color 0.2s ease-in-out;
}

.custom-button:hover {
  background: #d3317d;
}
.sk-right-notification {
  padding: 0 !important;
}

.sk-right-notification ul li {
  padding: 12px 30px;
  transition: 0.5s;
}

.sk-right-notification ul li a {
  display: block;
}

.sk-right-notification ul li a:hover {
  background: transparent;
  color: #202020;
  text-decoration: none;
}

.sk-right-notification ul li:hover {
  background: #f5f5f5;
  color: #fff;
}

.sk-right-notification ul li:hover a {
  color: #d3317d;
}

.sk-right-notification p {
  text-align: center;
  padding: 20px;
}

.notification-time {
  display: block;
  font-size: 10px;
  color: #d3317d;
}
@media screen and (max-width: 480px) {
  .rs-drawer-left.rs-drawer-xs, .rs-drawer-right.rs-drawer-xs{
    width: 100%;
    max-width: 100%;
  }
  .rs-drawer-header {
   padding: 10px 20px 10px 50px !important;
  }
  .rs-drawer-header .rs-drawer-header-close {
    top: 20px !important;
  }
  .rs-btn-icon.rs-btn-sm{
    padding: 0 !important;
  }
  .rs-drawer-title{
    font-size: 14px !important;
  }
  .sk-right-notification ul li{
    padding: 10px 20px;
  }
}