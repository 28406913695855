@import "../../Styles/variable";
body {
  font-family: "Poppins", sans-serif;
}

.login_main .logincontentbg {
  position: relative;
  // background-image: url("../../assets/images/login.png");
  background-size: auto;
  background-repeat: no-repeat;
  background-position: left bottom;
  height: 100%;
}
.login_bg_main .logincontentbg {
  position: relative;
  // background-image: url("../../assets/images/login.png");
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: contain;
  height: 100%;
}
.logincontentbg img.girl_img {
  max-width: 100%;
}
input:-internal-autofill-selected {
  background-color: red !important;
  appearance: inherit;
  padding: 8px;
}
input:autofill {
  border: 0px solid blue !important;
}
.login_main .login_content h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 24px;
  color: rgb(214 44 121);
}
.login_content {
  float: right;
  margin-top: 17px;
  padding: 0px 0px 0px 0px;
}
.login_content .login-content-right h5 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 20px;
  color: #020202;
  text-transform: capitalize;
}
.login_content .login-content-right p {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: #535353;
}
.btn.btn-google {
  background: #ffffff;
  border: 1px solid #e3e4eb;
  box-sizing: border-box;
  box-shadow: 0px 2px 15px rgb(0 0 0 / 10%);
  border-radius: 5px;
  width: 100%;
  padding: 10px 0px;
  margin: auto;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 13px;
  color: #4f4f4f;
  height: 40px;
  margin-top: 10px;
}
.orimg {
  max-width: 98%;
}

.login_form label {
  font-weight: normal;
  font-size: 14px;
  color: #425466;
  margin-bottom: 0px;
}
.login_form label span {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #c42020;
  vertical-align: middle;
}
.login_form .form-control {
  background: #ffffff;
  border: 1px solid #e6e5eb;
  box-sizing: border-box;
  border-radius: 5px;
  height: 40px;
  padding: 0px;
}
.login_form .form-control:focus {
  box-shadow: none;
  border-color: #d62c79;
}
.btn.btn_login {
  background: #ec498a;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  transition: 0.25s ease;
  margin-top: 10px;
  margin-bottom: 20px;
}

.btn.btn_login:hover {
  background: #ffffff;
  // border: 1px solid #ec498a;
  color: #ec498a; 
  // border-radius: 2px;
  transition: 1s ease;

}
.login_form .policy_para {
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
  font-weight: 300;
  font-size: 12px;
  color: #020202;
}
.login_form .policy_para a {
  color: #d62c79;
  font-weight: 600;
}
.login_form .policy_para a:hover {
  text-decoration: none;
}
.login_form span.asterik {
  color: #c42020;
  font-size: 16px;
  padding-left: 3px;
}
.form-group:after {
  color: red;
  font-size: 14px;
  position: absolute;
  top: 9px;
  left: 36px;
}
.form-group.rem_s::after {
  color: #fff;
}
