.pop-up-ads-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  margin: auto;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  backdrop-filter: blur(4px);
  background: rgb(31 27 27 / 45%);
}
.sk-ad-windows {
  background: #f3f3f3;
  text-align: left;
}
.pop-up-ads {
  position: absolute;
  width: auto;
  height: auto;
  max-width: 100%;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  text-align: center;

  display: inline-flex;
}

.close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  background-color: #f2f2f2;
  border: none;
  cursor: pointer;
  color: #888;
  font-size: 21px;
  line-height: 1;
}

.close-button:hover {
  background-color: #ddd;
}

.close-button:focus {
  outline: none;
}

.right-bottom {
  bottom: 10px;
  left: 10px;
}
.pop-up-ads.left-bottom {
  display: flex;
  align-items: center;
}
// .pop-up-ads.right-bottom {
//   bottom: 0;
// }

.pop-up-ads.left-bottom img {
  height: auto;
  width: 100%;
  max-width: 450px;
  margin: auto;
}

.pop-up-ads.right-bottom video {
  height: auto;
  width: 100%;
  padding: 5px;
  max-width: 400px;
}

@media (max-width: 767px) {
  .pop-up-ads {
    width: 100%;
    max-width: 320px;
  }
  .pop-up-ads video {
    max-width: 320px;
  }

  .close-button {
    padding: 5px;
    font-size: 20px;
  }

  .pop-up-ads.left-bottom img {
    height: auto;
    width: 100%;
    max-width: 300px;
    margin: auto;
  }

  .pop-up-ads.right-bottom video {
    height: auto;
    width: 100%;
    padding: 5px;
    max-width: 250px;
  }
}
